import {
  Alert,
  Grid,
  Paper,
  Snackbar,
  Typography
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import Resizer from "react-image-file-resizer";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../apis";
import RequestNewForm from "./request-forms/RequestNewForm";

export default function RequestAddPage() {
  const params = useParams();
  const caseId = parseInt(params.id);
  const navigate = useNavigate();
  const [item, setItem] = useState({});

  const [letterH, setLetterH] = useState(false);
  const [letter, setLetter] = useState(false);
  const [letterA, setLetterA] = useState(false);
  const [letterB, setLetterB] = useState(false);
  const [letterQ, setLetterQ] = useState(false);
  const [letterR, setLetterR] = useState(false);
  const [letterD, setLetterD] = useState(false);
  const [letterE, setLetterE] = useState(false);

  const [fetchError, SetFetchError] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchError(false);
  };

  const validateRules = () => ({
    bank_id: Joi.any()
      .messages({
        "string.empty": "กรุณาระบุธนาคาร",
        "any.required": "กรุณาระบุธนาคาร",
      })
      .required(),
    bank_account: Joi.any()
      .messages({
        "string.empty": "กรุณาระบุบัญชีธนาคาร",
        "any.required": "กรุณาระบุบัญชีธนาคาร",
      })
      .required(),

    ...(letterA
      ? {
          noA: Joi.string()
            .messages({
              "string.empty": "กรุณาระบุเลขหนังสือ",
              "any.required": "กรุณาระบุเลขหนังสือ",
            })
            .required(),
          dateA: Joi.string()
            .messages({
              "string.empty": "กรุณาระบุวันที่",
              "any.required": "กรุณาระบุวันที่",
            })
            .required(),
        }
      : {}),

    ...(letterB
      ? {
          noB: Joi.string()
            .messages({
              "string.empty": "กรุณาระบุเลขหนังสือ",
              "any.required": "กรุณาระบุเลขหนังสือ",
            })
            .required(),
          dateB: Joi.string()
            .messages({
              "string.empty": "กรุณาระบุวันที่",
              "any.required": "กรุณาระบุวันที่",
            })
            .required(),
        }
      : {}),
    ...(letterR
      ? {
          noR: Joi.string()
            .messages({
              "string.empty": "กรุณาระบุเลขหนังสือ",
              "any.required": "กรุณาระบุเลขหนังสือ",
            })
            .required(),
          dateR: Joi.string()
            .messages({
              "string.empty": "กรุณาระบุวันที่",
              "any.required": "กรุณาระบุวันที่",
            })
            .required(),
        }
      : {}),

    ...(letterQ
      ? {
          noQ: Joi.string()
            .messages({
              "string.empty": "กรุณาระบุเลขหนังสือ",
              "any.required": "กรุณาระบุเลขหนังสือ",
            })
            .required(),
          dateQ: Joi.string()
            .messages({
              "string.empty": "กรุณาระบุวันที่",
              "any.required": "กรุณาระบุวันที่",
            })
            .required(),
        }
      : {}),
    ...(letterD
      ? {
          noD: Joi.string()
            .messages({
              "string.empty": "กรุณาระบุเลขหนังสือ",
              "any.required": "กรุณาระบุเลขหนังสือ",
            })
            .required(),
          dateD: Joi.string()
            .messages({
              "string.empty": "กรุณาระบุวันที่",
              "any.required": "กรุณาระบุวันที่",
            })
            .required(),
        }
      : {}),
    ...(letterE
      ? {
          noE: Joi.string()
            .messages({
              "string.empty": "กรุณาระบุเลขหนังสือ",
              "any.required": "กรุณาระบุเลขหนังสือ",
            })
            .required(),
          dateE: Joi.string()
            .messages({
              "string.empty": "กรุณาระบุวันที่",
              "any.required": "กรุณาระบุวันที่",
            })
            .required(),
        }
      : {}),

    rank: Joi.string()
      .max(255)
      .messages({
        "string.empty": "กรุณาระบุยศ",
        "any.required": "กรุณาระบุยศ",
      })
      .required(),
    fname: Joi.string()
      .max(255)
      .messages({
        "string.empty": "กรุณาระบุชื่อ",
        "any.required": "กรุณาระบุชื่อ",
      })
      .required(),
    lname: Joi.string()
      .max(255)
      .messages({
        "string.empty": "กรุณาระบุนามสกุล",
        "any.required": "กรุณาระบุนามสกุล",
      })
      .required(),
    job_title: Joi.string()
      .max(255)
      .messages({
        "string.empty": "กรุณาระบุตำแหน่ง",
        "any.required": "กรุณาระบุตำแหน่ง",
      })
      .required(),
    email: Joi.string()
      .messages({
        "string.empty": "กรุณาระบุอีเมล",
        "any.required": "กรุณาระบุอีเมล",
      })
      .required(),
    policestation_org_code: Joi.string()
      .pattern(/^\d{5}$/)
      .messages({
        "string.empty": "กรุณาระบุหน่วยงาน",
        "any.required": "กรุณาระบุหน่วยงาน",
      })
      .required(),
    pol_tel: Joi.string()
      .pattern(/^\d{10}$/)
      .messages({
        "string.empty": "กรุณาระบุเบอร์โทร",
        "any.required": "กรุณาระบุเบอร์โทร",
      })
      .required(),
    address: Joi.string()
      .messages({
        "string.empty": "กรุณาระบุที่อยู่",
        "any.required": "กรุณาระบุที่อยู่",
      })
      .required(),
    profile_signature: Joi.any().messages({
      "string.empty": "กรุณาอัพโหลดลายเซ็น",
      "any.required": "กรุณาอัพโหลดลายเซ็น",
    }).required(),
  });
  const schema = () => Joi.object(validateRules());

  const validate = (values, props) => {
    const errors = {};
    const vResult = schema().validate(values, {
      abortEarly: false,
      allowUnknown: true,
    });

    if (vResult.error) {
      let details = vResult.error.details;
      // console.log({details})
      details.forEach(({ context, message }) => {
        _.set(errors, context.label, message);
        errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
      });
    }
    return errors;
  };

  useEffect(() => {
    let isSubscribed = true;
    api
      .get(`/api/me`)
      .then(({ data }) => {
        // console.log(data.permisstions.map((v) => v.option.map((v) => v.info)));
        isSubscribed && setItem(data);
      })
      .catch(({ response }) => {
        if (response && response.status === 404) {
          SetFetchError("เกิดข้อผิดพลาด");
        }
        SetFetchError("เกิดข้อผิดพลาด");
      });
    return () => {
      isSubscribed = false;
    };
  }, []);

  const onSubmit = async (formData) => {

    if(!formData.profile_signature){
      return {
        [FORM_ERROR]: "กรุณาอัพโหลดลายเซ็น",
        profile_signature: "กรุณาอัพโหลดลายเซ็น",
      };
    }

    const dataAll = [];
    if (letter) {
      const data = {
        request_type_id: 1,
        letter: "urgent",
        data: {
          bank_account: formData.bank_account,
          bank_accounts: formData.bank_accounts,
          bank_id: formData.bank_id,
        },
      };
      dataAll.push(data);
    }
    if (letterA) {
      const dataA = {
        request_type_id: 2,
        letter: "account",
        data: {
          address: formData.addressA,
          bank_account: formData.bank_account,
          bank_accounts: formData.bank_accountsA,
          bank_id: formData.bank_id,
          date: formData.dateA,
          email: formData.emailA,
          fname: formData.fnameA,
          job_title: formData.job_titleA,
          lname: formData.lnameA,
          no: formData.noA,
          policestation_org_code: formData.policestation_org_codeA,
          rank: formData.rankA,
          signature: formData.signatureA,
          tel: formData.telA,
        },
      };
      dataAll.push(dataA);
    }
    if (letterB) {
      const dataB = {
        request_type_id: 3,
        letter: "statement",
        data: {
          address: formData.addressB,
          bank_account: formData.bank_account,
          bank_accounts: formData.bank_accountsB,
          bank_id: formData.bank_id,
          date: formData.dateB,
          email: formData.emailB,
          fname: formData.fnameB,
          job_title: formData.job_titleB,
          lname: formData.lnameB,
          no: formData.noB,
          policestation_org_code: formData.policestation_org_codeB,
          rank: formData.rankB,
          signature: formData.signatureB,
          tel: formData.telB,
        },
      };
      dataAll.push(dataB);
    }
    if (letterR) {
      const dataR = {
        request_type_id: 4,
        letter: "accountR",
        data: {
          address: formData.addressR,
          bank_account: formData.bank_account,
          bank_accounts: formData.bank_accountsR,
          bank_id: formData.bank_id,
          date: formData.dateR,
          email: formData.emailR,
          fname: formData.fnameR,
          job_title: formData.job_titleR,
          lname: formData.lnameR,
          no: formData.noR,
          policestation_org_code: formData.policestation_org_codeR,
          rank: formData.rankR,
          signature: formData.signatureR,
          tel: formData.telR,
        },
      };
      dataAll.push(dataR);
    }
    if (letterQ) {
      const dataQ = {
        request_type_id: 5,
        letter: "amount",
        data: {
          address: formData.addressB,
          bank_account: formData.bank_account,
          bank_accounts: formData.bank_accountsQ,
          bank_id: formData.bank_id,
          date: formData.dateQ,
          email: formData.emailQ,
          fname: formData.fnameQ,
          job_title: formData.job_titleQ,
          lname: formData.lnameQ,
          no: formData.noQ,
          policestation_org_code: formData.policestation_org_codeQ,
          rank: formData.rankQ,
          signature: formData.signatureQ,
          tel: formData.telQ,
        },
      };
      dataAll.push(dataQ);
    }
    if (letterD) {
      const dataD = {
        request_type_id: 6,
        letter: "atm",
        data: {
          address: formData.addressD,
          bank_account: formData.bank_account,
          bank_accounts: formData.bank_accountsD,
          bank_id: formData.bank_id,
          date: formData.dateD,
          email: formData.emailD,
          fname: formData.fnameD,
          job_title: formData.job_titleD,
          lname: formData.lnameD,
          no: formData.noD,
          policestation_org_code: formData.policestation_org_codeD,
          rank: formData.rankD,
          signature: formData.signatureD,
          tel: formData.telD,
        },
      };
      dataAll.push(dataD);
    }
    if (letterE) {
      const dataE = {
        request_type_id: 7,
        letter: "cctv",
        data: {
          address: formData.addressE,
          bank_account: formData.bank_account,
          bank_id: formData.bank_id,
          date: formData.dateE,
          email: formData.emailE,
          fname: formData.fnameE,
          job_title: formData.job_titleE,
          lname: formData.lnameE,
          no: formData.noE,
          policestation_org_code: formData.policestation_org_codeE,
          rank: formData.rankE,
          signature: formData.signatureE,
          tel: formData.telE,
          items: formData.bank_accountsE.map((item) => item.bank_account_id),
        },
      };
      dataAll.push(dataE);
    }
    if (letterH) {
      const dataH = {
        request_type_id: 8,
        letter: "confirmCfr",
        data: {
          address: formData.addressH,
          bank_account: formData.bank_account,
          bank_case_id: formData.bank_case_id,
          bank_id: formData.bank_id,
          date: formData.dateH,
          email: formData.emailH,
          fname: formData.fnameH,
          job_title: formData.job_titleH,
          lname: formData.lnameH,
          no: formData.noH,
          policestation_org_code: formData.policestation_org_codeH,
          rank: formData.rankH,
          signature: formData.signatureH,
          tel: formData.telH,
          victim_card_id: formData.victim_card_id,
        },
      };
      dataAll.push(dataH);
    }

    const dataSubmit = [];

    const user = {
      rank: formData.rank,
      fname: formData.fname,
      lname: formData.lname,
      job_title: formData.job_title,
      policestation_org_code: formData.policestation_org_code,
      email: formData.email,
      pol_tel: formData.pol_tel,
      address: formData.address,
      profile_signature:
        formData.profile_signature && formData.profile_signature instanceof File
          ? await signatureResize(formData.profile_signature)
          : formData.profile_signature
          ? formData.profile_signature
          : undefined,
    };

    for (let i = 0; i < dataAll.length; i++) {
      let submitData = {};

      if (
        dataAll[i].data.signature &&
        dataAll[i].data.signature instanceof File
      ) {
        // resize before submit
        try {
          const signatureBase64 = await signatureResize(
            dataAll[i].data.signature
          );
          submitData = {
            request_type_id: dataAll[i].request_type_id,
            letter: dataAll[i].letter,
            data: { ...dataAll[i].data, signature: signatureBase64 },
          };
        } catch (e) {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
            signature: "เกิดข้อผิดพลาด",
          };
        }
      } else {
        submitData = {
          request_type_id: dataAll[i].request_type_id,
          letter: dataAll[i].letter,
          data: dataAll[i].data,
        };
      }
      dataSubmit.push(submitData);
    }

    try {
      const res = await api.post(`/api/cases/${caseId}/pol_requests`, {
        user,
        requests: dataSubmit,
      });

      if (res.data.id) {
        navigate(`/cases/${caseId}/requests/${res.data.id}`);
      } else {
        navigate(`/cases/${caseId}/mgt`);
      }
    } catch ({ response }) {
      if (response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);

        // convert laravel dot notation key to object
        const normalizeKeyError = {};
        for (const [key, value] of Object.entries(errors)) {
          _.set(normalizeKeyError, key, value);
        }

        normalizeKeyError[FORM_ERROR] = response.data.message;

        // console.log(normalizeKeyError);
        return normalizeKeyError;
      } else {
        return {
          [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
        };
      }
    }
  };

  return (
    <>
      <Snackbar
        open={fetchError ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {fetchError}
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
        <Typography variant="h5">สร้างคำขอ (ID: {caseId} )</Typography>
      </Grid>
      {/* <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Form
            validate={validate}
            onSubmit={onSubmit}
            component={RequestForm}
          />
        </Paper>
      </Grid> */}
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Form
            validate={validate}
            onSubmit={onSubmit}
            component={RequestNewForm}
            initialValues={mapToInit(item)}
            setLetter={setLetter}
            setLetterH={setLetterH}
            setLetterA={setLetterA}
            setLetterB={setLetterB}
            setLetterQ={setLetterQ}
            setLetterR={setLetterR}
            setLetterD={setLetterD}
            setLetterE={setLetterE}
          />
        </Paper>
      </Grid>
    </>
  );
}

const signatureResize = (file) => {
  const fileType = file.type === "image/png" ? "PNG" : "JPEG";
  const compress = file.type === "image/png" ? 100 : 90;
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      90,
      50,
      fileType,
      compress,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });
};

export const mapToInit = (item) => {
  return item;
};

// const mapToInit = (item) => {
//   return _.pick(item, Object.keys(validateRules));
// };
