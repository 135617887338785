import api from "../apis";
import _ from "lodash";

export const REQUEST_REPORT_FETCH = "report/REQUEST_REPORT_FETCH";
export const REQUEST_REPORT_CLEAR_RESULT = "report/REQUEST_REPORT_CLEAR_RESULT";
export const REQUEST_REPORT_MARK_LOADING = "report/REQUEST_REPORT_MARK_LOADING";
export const REQUEST_REPORT_SET_FILTER = "report/REQUEST_REPORT_SET_FILTER";

export const HR03_REPORT_FETCH = "report/HR03_REPORT_FETCH";
export const HR03_REPORT_CLEAR_RESULT = "report/HR03_REPORT_CLEAR_RESULT";
export const HR03_REPORT_MARK_LOADING = "report/HR03_REPORT_MARK_LOADING";
export const HR03_REPORT_SET_FILTER = "report/HR03_REPORT_SET_FILTER";

export const DATA_REPORT_LOADING = "report/DATA_REPORT_LOADING";
export const DATA_REPORT_FETCH = "report/DATA_REPORT_FETCH";
export const DATA_REPORT_CLEAR_RESULT = "report/DATA_REPORT_CLEAR_RESULT";
export const DATA_REPORT_MARK_LOADING = "report/DATA_REPORT_MARK_LOADING";
export const DATA_REPORT_SET_FILTER = "report/DATA_REPORT_SET_FILTER";

export const DATA_EXTERNAL_REPORT_FETCH = "report/DATA_EXTERNAL_REPORT_FETCH";
export const DATA_EXTERNAL_REPORT_CLEAR_RESULT =
  "report/DATA_EXTERNAL_REPORT_CLEAR_RESULT";
export const DATA_EXTERNAL_REPORT_MARK_LOADING =
  "report/DATA_EXTERNAL_REPORT_MARK_LOADING";
export const DATA_EXTERNAL_REPORT_SET_FILTER =
  "report/DATA_EXTERNAL_REPORT_SET_FILTER";

export const ASSIGN_REPORT_LOADING = "report/ASSIGN_REPORT_LOADING";
export const ASSIGN_REPORT_FETCH = "report/ASSIGN_REPORT_FETCH";
export const ASSIGN_REPORT_CLEAR_RESULT = "report/ASSIGN_REPORT_CLEAR_RESULT";
export const ASSIGN_REPORT_MARK_LOADING = "report/ASSIGN_REPORT_MARK_LOADING";
export const ASSIGN_REPORT_SET_FILTER = "report/ASSIGN_REPORT_SET_FILTER";

export const USAGE_REPORT_LOADING = "report/USAGE_REPORT_LOADING";
export const USAGE_REPORT_FETCH = "report/USAGE_REPORT_FETCH";
export const USAGE_REPORT_CLEAR_RESULT = "report/USAGE_REPORT_CLEAR_RESULT";
export const USAGE_REPORT_MARK_LOADING = "report/USAGE_REPORT_MARK_LOADING";
export const USAGE_REPORT_SET_FILTER = "report/USAGE_REPORT_SET_FILTER";

const defaultState = {
  request_report: {},
  metaRequestReport: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
  },
  hr03_report: {},
  metaHr03Report: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
  },
  data_report: {},
  metaDataReport: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
  },
  data_external_report: {},
  metaDataExternalReport: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
  },
  assign_report: {},
  metaAssignReport: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
  },
  usage_report: {},
  metaUsageReport: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
  },
};

// Reducer
export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case REQUEST_REPORT_FETCH:
      return {
        ...state,
        request_report: action.payload,
        metaRequestReport: {
          ...state.metaRequestReport,
          loading: false,
          total: action?.payload?.total,
          // page: action.payload.page,
          // pageSize: action.payload.pageSize,
        },
      };
    case REQUEST_REPORT_CLEAR_RESULT:
      return {
        ...state,
        request_report: action.payload,
        metaRequestReport: {
          ...state.metaRequestReport,
          loading: false,
          total: action?.payload?.total,
          // page: action.payload.page,
          // pageSize: action.payload.pageSize,
        },
      };
    case REQUEST_REPORT_SET_FILTER:
      return {
        ...state,
        metaRequestReport: {
          ...state.metaRequestReport,
          filter: action.payload,
          page: 0,
        },
      };
    case HR03_REPORT_FETCH:
      return {
        ...state,
        hr03_report: action.payload,
        metaHr03Report: {
          ...state.metaHr03Report,
          loading: false,
          total: action?.payload?.total,
          // page: action.payload.page,
          // pageSize: action.payload.pageSize,
        },
      };
    case HR03_REPORT_CLEAR_RESULT:
      return {
        ...state,
        hr03_report: action.payload,
        metaHr03Report: {
          ...state.metaHr03Report,
          loading: false,
          total: action?.payload?.total,
          // page: action.payload.page,
          // pageSize: action.payload.pageSize,
        },
      };
    case HR03_REPORT_SET_FILTER:
      return {
        ...state,
        metaHr03Report: {
          ...state.metaHr03Report,
          filter: action.payload,
          page: 0,
        },
      };
    case DATA_REPORT_LOADING:
      return {
        ...state,
        metaDataReport: {
          ...state.metaDataReport,
          loading: action.payload,
        },
      };
    case DATA_REPORT_FETCH:
      return {
        ...state,
        data_report: action.payload,
        metaDataReport: {
          ...state.metaDataReport,
          loading: false,
          // total: action?.payload?.total,
          // page: action.payload.page,
          // pageSize: action.payload.pageSize,
        },
      };
    case DATA_REPORT_CLEAR_RESULT:
      return {
        ...state,
        data_report: action.payload,
        metaDataReport: {
          ...state.metaDataReport,
          loading: false,
          total: action?.payload?.total,
          // page: action.payload.page,
          // pageSize: action.payload.pageSize,
        },
      };
    case DATA_REPORT_SET_FILTER:
      return {
        ...state,
        metaDataReport: {
          ...state.metaDataReport,
          filter: action.payload,
          page: 0,
        },
      };
    case DATA_EXTERNAL_REPORT_FETCH:
      return {
        ...state,
        data_external_report: action.payload,
        metaDataExternalReport: {
          ...state.metaDataReport,
          loading: false,
          total: action?.payload?.total,
          // page: action.payload.page,
          // pageSize: action.payload.pageSize,
        },
      };
    case DATA_EXTERNAL_REPORT_CLEAR_RESULT:
      return {
        ...state,
        data_external_report: action.payload,
        metaDataExternalReport: {
          ...state.metaDataReport,
          loading: false,
          total: action?.payload?.total,
          // page: action.payload.page,
          // pageSize: action.payload.pageSize,
        },
      };
    case DATA_EXTERNAL_REPORT_SET_FILTER:
      return {
        ...state,
        metaDataExternalReport: {
          ...state.metaDataReport,
          filter: action.payload,
          page: 0,
        },
      };
    case ASSIGN_REPORT_LOADING:
      return {
        ...state,
        metaAssignReport: {
          ...state.metaAssignReport,
          loading: action.payload,
        },
      };
    case ASSIGN_REPORT_FETCH:
      return {
        ...state,
        assign_report: action.payload,
      };
    case ASSIGN_REPORT_CLEAR_RESULT:
      return {
        ...state,
        assign_report: action.payload,
        metaAssignReport: {
          ...state.metaAssignReport,
          loading: false,
          total: action?.payload?.total,
          // page: action.payload.page,
          // pageSize: action.payload.pageSize,
        },
      };
    case ASSIGN_REPORT_SET_FILTER:
      return {
        ...state,
        metaAssignReport: {
          ...state.metaAssignReport,
          filter: action.payload,
          page: 0,
        },
      };
    case USAGE_REPORT_LOADING:
      return {
        ...state,
        metaUsageReport: {
          loading: action.payload,
        },
      };
    case USAGE_REPORT_FETCH:
      return {
        ...state,
        usage_report: action.payload,
      };
    case USAGE_REPORT_CLEAR_RESULT:
      return {
        ...state,
        usage_report: action.payload,
        metaUsageReport: {
          ...state.metaUsageReport,
          loading: false,
          total: action?.payload?.total,
          // page: action.payload.page,
          // pageSize: action.payload.pageSize,
        },
      };
    case USAGE_REPORT_SET_FILTER:
      return {
        ...state,
        metaUsageReport: {
          ...state.metaUsageReport,
          filter: action.payload,
          page: 0,
        },
      };
    default:
      return state;
  }
}

export const fetchRequestReport =
  (pageSize, page, filter = {}, fetchId, fetchIdRef) =>
  async (dispatch) => {
    let response = null;

    dispatch(requestReportLoading(true));

    try {
      response = await api.get(`/api/mgt/pol_requests/list_request`, {
        params: {
          page_size: pageSize,
          page: page,
          ...filter,
        },
      });
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        dispatch({
          type: REQUEST_REPORT_FETCH,
          payload: { ...response.data, pageSize, page },
        });
      }
    } catch (e) {
      const { response } = e;
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.error(errors);
      }
      dispatch(requestReportClearResult());
      throw e;
    }
  };

export const requestReportClearResult = () => {
  return { type: REQUEST_REPORT_CLEAR_RESULT };
};

export const requestReportLoading = (isLoading = true) => {
  return { type: REQUEST_REPORT_MARK_LOADING, payload: isLoading };
};

export const requestReportFilter = (filterValue) => {
  return { type: REQUEST_REPORT_SET_FILTER, payload: filterValue };
};

export const fetchHr03Report =
  (pageSize, page, filter = {}, fetchId, fetchIdRef) =>
  async (dispatch) => {
    let response = null;

    dispatch(hr03ReportLoading(true));

    try {
      response = await api.get(`/api/hr_reports`, {
        params: {
          page_size: pageSize,
          page: page,
          ...filter,
        },
      });
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        dispatch({
          type: HR03_REPORT_FETCH,
          payload: { ...response.data, pageSize, page },
        });
      }
    } catch (e) {
      const { response } = e;
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.error(errors);
      }
      dispatch(hr03ReportClearResult());
      throw e;
    }
  };

export const hr03ReportLoading = (isLoading = true) => {
  return { type: HR03_REPORT_MARK_LOADING, payload: isLoading };
};

export const hr03ReportClearResult = () => {
  return { type: HR03_REPORT_CLEAR_RESULT };
};

export const hr03ReportFilter = (filterValue) => {
  return { type: HR03_REPORT_SET_FILTER, payload: filterValue };
};

export const fetchDataReport =
  (filter = {}, fetchId, fetchIdRef) =>
  async (dispatch) => {
    let response = null;
    dispatch({
      type: DATA_REPORT_LOADING,
      payload: true,
    });

    try {
      response = await api.get(`/api/reports/list`, {
        params: {
          ...filter,
        },
      });
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        dispatch({
          type: DATA_REPORT_FETCH,
          payload: { ...response.data },
        });
      }
    } catch (e) {
      const { response } = e;
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.error(errors);
      }
      dispatch(dataReportClearResult());
      throw e;
    } finally {
      dispatch({
        type: DATA_REPORT_LOADING,
        payload: false,
      });
    }
  };

export const dataReportClearResult = () => {
  return { type: DATA_REPORT_CLEAR_RESULT };
};

export const dataReportLoading = (isLoading = true) => {
  return { type: DATA_REPORT_MARK_LOADING, payload: isLoading };
};

export const dataReportFilter = (filterValue) => {
  return { type: DATA_REPORT_SET_FILTER, payload: filterValue };
};

export const fetchDataExternalReport =
  (filter = {}, fetchId, fetchIdRef) =>
  async (dispatch) => {
    let response = null;

    dispatch(requestReportLoading(true));

    try {
      response = await api.get(`/api/reports_external_department`, {
        params: {
          ...filter,
        },
      });
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        dispatch({
          type: DATA_EXTERNAL_REPORT_FETCH,
          payload: { ...response.data },
        });
      }
    } catch (e) {
      const { response } = e;
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.error(errors);
      }
      dispatch(dataExternalReportClearResult());
      throw e;
    }
  };

export const dataExternalReportClearResult = () => {
  return { type: DATA_EXTERNAL_REPORT_CLEAR_RESULT };
};

export const dataExternalReportLoading = (isLoading = true) => {
  return { type: DATA_EXTERNAL_REPORT_MARK_LOADING, payload: isLoading };
};

export const dataExternalReportFilter = (filterValue) => {
  return { type: DATA_EXTERNAL_REPORT_SET_FILTER, payload: filterValue };
};

export const fetchAssignReport =
  (filter = {}, fetchId, fetchIdRef) =>
  async (dispatch) => {
    let response = null;

    dispatch({
      type: ASSIGN_REPORT_LOADING,
      payload: true,
    });

    try {
      response = await api.get(`/api/reports/caseassign`, {
        params: {
          ...filter,
        },
      });
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        dispatch({
          type: ASSIGN_REPORT_FETCH,
          payload: { ...response.data },
        });
      }
    } catch (e) {
      const { response } = e;
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.error(errors);
      }
      dispatch(assignReportClearResult());
      throw e;
    } finally {
      dispatch({
        type: ASSIGN_REPORT_LOADING,
        payload: false,
      });
    }
  };

export const assignReportClearResult = () => {
  return { type: ASSIGN_REPORT_CLEAR_RESULT };
};

export const assignReportLoading = (isLoading = true) => {
  return { type: ASSIGN_REPORT_MARK_LOADING, payload: isLoading };
};

export const assignReportFilter = (filterValue) => {
  return { type: ASSIGN_REPORT_SET_FILTER, payload: filterValue };
};

export const fetchUsageReport =
  (filter = {}, fetchId, fetchIdRef) =>
  async (dispatch) => {
    let response = null;

    dispatch({
      type: USAGE_REPORT_LOADING,
      payload: true,
    });

    try {
      response = await api.get(`/api/member_report`, {
        params: {
          ...filter,
        },
      });
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        dispatch({
          type: USAGE_REPORT_FETCH,
          payload: { ...response.data },
        });
      }
    } catch (e) {
      const { response } = e;
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.error(errors);
      }
      dispatch(usageReportClearResult());
      throw e;
    } finally {
      dispatch({
        type: USAGE_REPORT_LOADING,
        payload: false,
      });
    }
  };

export const usageReportClearResult = () => {
  return { type: USAGE_REPORT_CLEAR_RESULT };
};

export const usageReportLoading = (isLoading = true) => {
  return { type: USAGE_REPORT_MARK_LOADING, payload: isLoading };
};

export const usageReportFilter = (filterValue) => {
  return { type: USAGE_REPORT_SET_FILTER, payload: filterValue };
};
