import {
  Alert,
  Grid,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  requestClearResult,
  requestForceReload,
  requestSetFilter,
} from "../../../../ducks/masterDataList";

import { List } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import _ from "lodash";
import { Form } from "react-final-form";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { Navigate, useParams } from "react-router-dom";
import api from "../../../../apis";
import EditRequestModal from "./EditRequestModal";
import RequestSearchForm from "./RequestSearchForm";

export default function SettingRequest() {
  const dispatch = useDispatch();
  const { status: reqStatus } = useParams();
  const [search, setSearch] = React.useState({});
  const qState = useSelector((state) => state.auth.user.permissions);

  const {
    isLoading: typeLoading,
    data: typeData,
    refetch: typeRefetch,
  } = useQuery("request-type", () =>
    api
      .get(
        `/api/request/types?status=${_.get(
          search,
          "status",
          ""
        )}&search=${_.get(search, "search", "")}`
      )
      .then((res) => res.data)
  );

  const isValidStatus = useMemo(() => {
    return (
      reqStatus === undefined ||
      ["sent_to_contact_person", "checked"].includes(reqStatus)
    );
  }, [reqStatus]);

  useEffect(() => {
    return () => {
      dispatch(requestClearResult());
      dispatch(requestSetFilter({}));
    };
  }, [dispatch, reqStatus]);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  // const total = data.length;
  const loading = useSelector((state) => state.mgtRequestList.meta.loading);

  const [no, setNo] = React.useState(false);
  const handleClose = () => setNo(false);
  const [databyNo, setDatabyNo] = React.useState(null);

  // add modal state
  const [openModal, setOpenModal] = React.useState(false);
  const handleCloseModal = useCallback(
    (reason) => {
      if (reason) {
        if (reason.type === "error") {
          SetToastInfo(reason);
        } else {
          dispatch(requestForceReload());
        }
      }
      setOpenModal(false);
    },
    [dispatch]
  );

  useEffect(() => {
    typeRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const columns = [
    qState?.setting_request?.includes("view") ||
    qState?.new_request?.includes("edit")
      ? {
          field: "actions",
          headerName: "จัดการ",
          sortable: false,
          width: 100,
          renderCell: (params) => {
            return (
              <>
                <Tooltip title="แสดง">
                  <IconButton
                    type="button"
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setNo(params?.row?.value);
                      setDatabyNo(params?.row);
                    }}
                  >
                    <List />
                  </IconButton>
                </Tooltip>
              </>
            );
          },
        }
      : null,

    {
      field: "id",
      headerName: "ID",
      sortable: false,
      width: 70,
    },
    {
      field: "status",
      headerName: "สถานะใช้งาน",
      sortable: false,
      // minWidth: 180,
      // maxWidth: 180,
      renderCell: (params) => (
        <div className="flex items-center justify-center w-full">
          <label className="inline-flex relative items-center mr-5 cursor-pointer">
            <input
              checked={_.get(params, "row.status", false)}
              type="checkbox"
              className="sr-only peer"
              onClick={() => {
                onSaveData(
                  {
                    object_type: params.row.object_type,
                    status: !_.get(params, "row.status", false),
                  },
                  _.get(params, "row.id")
                );
              }}
            />
            <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-600"></div>
          </label>
        </div>
      ),
    },
    {
      field: "label",
      headerName: "หมายคำขอ",
      sortable: true,
      width: 500,
    },
    {
      field: "short_name",
      headerName: "ชื่อย่อหมายคำขอ",
      sortable: true,
      width: 125,
    },
    {
      field: "object_type",
      headerName: "Object",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        const objectType = params?.row?.object_type;
        const filter = {};
        Object.keys(objectType).forEach(
          (k) => objectType[k].value && (filter[k] = objectType[k])
        );
        const text = Object.values(filter)
          .map((val) => {
            return val.name;
          })
          .join(", ");
        return (
          <span className="flex justify-center items-center gap-1">{text}</span>
        );
      },
    },
    {
      field: "desc",
      headerName: "รายละเอียด",
      sortable: true,
      width: 600,
    },
  ]?.filter(Boolean);

  const onSearchSubmit = (formValues) => {
    setSearch(formValues);
    // dispatch(requestSetFilter(formValues));
  };

  if (!isValidStatus) {
    return <Navigate to="/" />;
  }

  const onSaveData = (body, id) => {
    const fetchData = async () => {
      const response = await api
        .patch(`/api/request/types/${id}`, body)
        .then((res) => {
          setNo(null);
          setDatabyNo(null);
          typeRefetch();
          return res;
        });
      return response;
    };
    const callFunction = fetchData();
    toast.promise(callFunction, {
      loading: <b>กำลังโหลด...</b>,
      success: <b>แก้ไขข้อมูลเสร็จสิ้น</b>,
      error: <b>ระบบเกิดความผิดพลาด กรุณาแจ้งเจ้าหน้าที่</b>,
    });
  };

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
      <Modal
        open={no ? true : false}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {
          <EditRequestModal
            databyNo={databyNo}
            setDatabyNo={setDatabyNo}
            no={no}
            setNo={setNo}
            onSaveData={onSaveData}
          />
        }
      </Modal>
      <Grid item xs={12}>
        <Typography variant="h5">ประเภทหมายคำขอ</Typography>
        <p>Master Data สำหรับตั้งค่าการใช้งานหมายคำขอ</p>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 800,
            width: "100%",
          }}
        >
          <Form
            // validate={validate}

            onSubmit={onSearchSubmit}
            component={RequestSearchForm}
            loading={loading}
            onReset={() => {
              dispatch(requestSetFilter({}));
              setSearch({});
            }}
            showStatusField={reqStatus ? false : true}
            page={typeData?.page}
            pageSize={typeData?.pageSize}
          />
          <DataGrid
            // density="compact"
            disableColumnMenu
            columns={columns}
            rows={typeData}
            loading={typeLoading}
            // rowsPerPageOptions={[5, 10, 15, 20]}
            pagination
          />
        </Paper>
      </Grid>
    </>
  );
}
