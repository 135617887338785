import { Paper, Typography } from "@mui/material";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../../../apis";
import { personalForceReload } from "../../../ducks/personalList";
import PersonalForm, { validate } from "./PersonalForm";
// import { forceReload as reloadAccountList } from "../../../ducks/accountList";
// import { forceReload as reloadTransactionList } from "../../../ducks/transactionSearch";

export default function PersonalAddPage({ handleCancel, tsFieldName }) {
  const params = useParams();
  const caseId = parseInt(params.id);
  const dispatch = useDispatch();
  const [nationality, setNationality] = useState(1); // 1 = คนไทย 2 = คนต่างชาติ

  const onSubmit = (formValues) => {
    const _nationality = { nationality: nationality };
    const objData = Object.assign({}, formValues, _nationality);
    return api
      .post(`/api/cases/${caseId}/persons`, objData)
      .then(({ data, status }) => {
        // if (status === 200) {
        dispatch(personalForceReload());
        handleCancel();
        // }
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }
          normalizeKeyError[FORM_ERROR] = response.data.message;
          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };
  return (
    <>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        gutterBottom
      >
        เพิ่มบุคคล
      </Typography>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Form
          validate={validate}
          onSubmit={onSubmit}
          component={PersonalForm}
          setNationality={setNationality}
          handleCancel={handleCancel}
          initialValues={{ nationality: 1 }}
        />
      </Paper>
    </>
  );
}
