import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  Box,
  Button,
  createTheme,
  Grid,
  Modal,
  ThemeProvider,
  Typography,
} from "@mui/material";
import thLocale from "date-fns/locale/th";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import "./assets/index.css";
import "./components/form-fields/css/dropzone.css";
import AccountSearchPage from "./components/pages/account-search/AccountSearchPage";
import NotifyLog from "./components/pages/auditLog/notifyLog/SettingNotifyLog";
import MasterLog from "./components/pages/auditLog/masterLog/SettingMasterLog";
import PermissionLog from "./components/pages/auditLog/permissionLog/SettingPermissionLog";
import TransactionLog from "./components/pages/auditLog/transactionLog/SettingTransactionLog";
import UserLog from "./components/pages/auditLog/userLog/SettingUserLog";
import CaseAssignList from "./components/pages/case-assign/caseAssignList";
import CaseMgtPage from "./components/pages/cases-mgt/CaseMgtPage";
import CaseAddPage from "./components/pages/cases/CaseAddPage";
import CaseListPage from "./components/pages/cases/CaseListPage";
import ChPassowrdPage from "./components/pages/ChPassowrdPage";
import Dashboard from "./components/pages/dashboard/Dashboard";
import DowloadFilePage from "./components/pages/DowloadFilePageToken";
import ForceSetPasswordPage from "./components/pages/ForceSetPasswordPage";
import Hr03MgtListPage from "./components/pages/hr03-mgt/Hr03MgtListPage";
import Hr03AccountAddPage from "./components/pages/hr03/Hr03AccountAddPage";
import Hr03AccountEditPage from "./components/pages/hr03/Hr03AccountEditPage";
import Hr03ListPage from "./components/pages/hr03/Hr03ListPage";
import Hr03RequestActive from "./components/pages/hr03/Hr03RequestActive";
import Hr03RequestAddPage from "./components/pages/hr03/Hr03RequestActive/Hr03RequestActiveAddPage";
import Hr03RequestEditPage from "./components/pages/hr03/Hr03RequestActive/Hr03RequestActiveEditPage";
import HR03Meeting from "./components/pages/hr03/Hr03WaitingMeeting/Hr03MeetingForm";
import InitFail from "./components/pages/InitFail";
import LoadingPage from "./components/pages/LoadingPage";
import LoginPage from "./components/pages/LoginPage";
import MaPage from "./components/pages/MaPage";
import AgencyAddPage from "./components/pages/master-data/agency/AgencyAddPage";
import AgencyEditPage from "./components/pages/master-data/agency/AgencyEditPage";
import SettingAgency from "./components/pages/master-data/agency/SettingAgency";
import ChannelSendingEditPage from "./components/pages/master-data/channel-sending/ChannelSendingEditPage";
import SettingChannelSending from "./components/pages/master-data/channel-sending/SettingChannelSending";
import SettingRequest from "./components/pages/master-data/request-letter/SettingRequest";
import Monitor from "./components/pages/monitor/Monitor";
import VerifyEmailPage from "./components/pages/register/VerifyEmailPage";
import ForgotPasswordPage from "./components/pages/register/ForgotPasswordPage";
import ForgotSetPasswordPage from "./components/pages/register/ForgotSetPasswordPage";
import RegisterPage from "./components/pages/register/RegisterPage";
import UserActivationPage from "./components/pages/register/UserActivationPage";
import DataExternalReport from "./components/pages/report/DataExternalReport";
import DataReport from "./components/pages/report/DataReport";
import AssignReport from "./components/pages/report/AssignReport";
import UsageReport from "./components/pages/report/UsageReport";
import HR03Report from "./components/pages/report/HR03Report";
import RequestReport from "./components/pages/report/RequestReport";
import CheckedSumRequestPage from "./components/pages/request-mgt/CheckedSumRequestPage";
import RequestListPage from "./components/pages/request-mgt/RequestListPage";
import SumRequestPage from "./components/pages/request-mgt/sum-req/SumRequestPage";
import RequestSearchPage from "./components/pages/requests-search/RequestSearchPage";
import RequestTelcoAddPage from "./components/pages/requests-telco/RequestTelcoAddPage";
import RequestAddPage from "./components/pages/requests/RequestAddPage";
import RequestAddPageH from "./components/pages/requests/RequestAddPageH";
import RequestViewPage from "./components/pages/requests/RequestViewPage";
import RequestLetterList from "./components/pages/responses/RequestLetterList";
import ResponseUploadPage from "./components/pages/responses/uploads/ResponseUploadPage";
import Notification from "./components/pages/setting/Notification";
import PermissionAddPage from "./components/pages/setting/permission/PermissionAddPage";
import PermissionEditPage from "./components/pages/setting/permission/PermissionEditPage";
import SettingPermission from "./components/pages/setting/permission/SettingPermission";
import SettingUser from "./components/pages/setting/user/SettingUser";
import UserAddPage from "./components/pages/setting/user/UserAddPage";
import UserEditPage from "./components/pages/setting/user/UserEditPage";
import ExternalUser from "./components/pages/setting/userExternal/SettingUser";
import ExternalUserAdd from "./components/pages/setting/userExternal/UserAddPage";
import ExternalUserEdit from "./components/pages/setting/userExternal/UserEditPage";
import UploadPage from "./components/pages/template-norm/UploadPage";
import TwoFactorPage from "./components/pages/TwoFactor";
import Google2FAPage from "./components/pages/Google2FA";
import UploadSuspicious from "./components/pages/UploadSuspicious";
import UploadSuspiciousAdd from "./components/pages/UploadSuspicious/UploadSuspiciousAddPage";
import UploadSuspiciousEdit from "./components/pages/UploadSuspicious/UploadSuspiciousEditPage";
import LineCallbackPage from "./components/pages/user-profile/LineCallbackPage";
import ProfileViewEditPage from "./components/pages/user-profile/ProfileViewEditPage";
import RedirectIfAuthenticate from "./components/RedirectIfAuthenticate";
import RequireRoles from "./components/RequireRoles";
import "./components/templates/dataGridCellWrap.css";
import Main from "./components/templates/Main";
import { AUTH_PASSWORD_EXPIRED_ALERT, authLogout } from "./ducks/auth";
import Notfound from "./notfound";
import PrivateRoute from "./PrivateRoute";
import RequestViewAllPage from "./components/pages/requests-all/RequestViewPage";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  p: 4,
  overflow: "auto",
  textAlign: "center",
  "&:focus-visible": {
    outlineColor: "#fff!important",
  },
};

const mdTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Kanit",
      textTransform: "none",
      // fontSize: 16,
    },
  },
});

function App() {
  const dispatch = useDispatch();
  const password_expired_alert = useSelector(
    (state) => state.auth.user?.password_expired_alert
  );
  const password_is_expire = useSelector(
    (state) => state.auth.user?.password_is_expire
  );
  const isAuthFetching = useSelector((state) => state.auth.isFetching);
  const appInitSuccess = useSelector((state) => state.app.initSuccess);
  const appLoading = useSelector((state) => state.app.isLoading);
  const maintenance = useSelector((state) => state.app.maintenance.status);
  const dashboard = useSelector(
    (state) => state.auth.user?.permissions?.dashboard ?? []
  );
  const monitor = useSelector(
    (state) => state.auth.user?.permissions?.monitor ?? []
  );
  const cases = useSelector(
    (state) => state.auth.user?.permissions?.cases ?? []
  );
  const assign_case = useSelector(
    (state) => state.auth.user?.permissions?.assign_case ?? []
  );
  const requests = useSelector(
    (state) => state.auth.user?.permissions?.requests ?? []
  );
  const saving_account_HR03 = useSelector(
    (state) => state.auth.user?.permissions?.saving_account_HR03 ?? []
  );
  const verify_account_HR03 = useSelector(
    (state) => state.auth.user?.permissions?.verify_account_HR03 ?? []
  );
  const request_account_HR03 = useSelector(
    (state) => state.auth.user?.permissions?.request_account_HR03 ?? []
  );
  // const line_notification = useSelector(
  //   (state) => state.auth.user?.permissions?.line_notification ?? []
  // );
  const extorg_request_list = useSelector(
    (state) => state.auth.user?.permissions?.extorg_request_list ?? []
  );
  const extorg_template_upload = useSelector(
    (state) => state.auth.user?.permissions?.extorg_template_upload ?? []
  );
  const search_account = useSelector(
    (state) => state.auth.user?.permissions?.search_account ?? []
  );
  const organize_request = useSelector(
    (state) => state.auth.user?.permissions?.organize_request ?? []
  );
  const report_requests = useSelector(
    (state) => state.auth.user?.permissions?.report_requests ?? []
  );
  const report_HR03_data = useSelector(
    (state) => state.auth.user?.permissions?.report_HR03_data ?? []
  );
  const manage_new_request = useSelector(
    (state) => state.auth.user?.permissions?.manage_new_request ?? []
  );
  const new_request = useSelector(
    (state) => state.auth.user?.permissions?.new_request ?? []
  );
  const approved_request = useSelector(
    (state) => state.auth.user?.permissions?.approved_request ?? []
  );
  const approved_request_group = useSelector(
    (state) => state.auth.user?.permissions?.approved_request_group ?? []
  );
  const template_group = useSelector(
    (state) => state.auth.user?.permissions?.template_group ?? []
  );
  const all_request = useSelector(
    (state) => state.auth.user?.permissions?.all_request ?? []
  );
  const template_upload = useSelector(
    (state) => state.auth.user?.permissions?.template_upload ?? []
  );
  const HR03_filter = useSelector(
    (state) => state.auth.user?.permissions?.HR03_filter ?? []
  );
  const upload_suspicious = useSelector(
    (state) => state.auth.user?.permissions?.upload_suspicious ?? []
  );
  const file_normalization = useSelector(
    (state) => state.auth.user?.permissions?.file_normalization ?? []
  );
  const setting_police_station = useSelector(
    (state) => state.auth.user?.permissions?.setting_police_station ?? []
  );
  const setting_request = useSelector(
    (state) => state.auth.user?.permissions?.setting_request ?? []
  );
  const setting_agency = useSelector(
    (state) => state.auth.user?.permissions?.setting_agency ?? []
  );
  const setting_user = useSelector(
    (state) => state.auth.user?.permissions?.setting_user ?? []
  );
  const setting_permission = useSelector(
    (state) => state.auth.user?.permissions?.setting_permission ?? []
  );
  const setting_config = useSelector(
    (state) => state.auth.user?.permissions?.setting_config ?? []
  );
  const setting_external = useSelector(
    (state) => state.auth.user?.permissions?.setting_external ?? []
  );
  const transaction_log = useSelector(
    (state) => state.auth.user?.permissions?.transaction_log ?? []
  );
  const master_log = useSelector(
    (state) => state.auth.user?.permissions?.master_log ?? []
  );
  const permission_log = useSelector(
    (state) => state.auth.user?.permissions?.permission_log ?? []
  );
  const user_log = useSelector(
    (state) => state.auth.user?.permissions?.user_log ?? []
  );
  const notify_log = useSelector(
    (state) => state.auth.user?.permissions?.notify_log ?? []
  );
  // const hr03_log = useSelector(
  //   (state) => state.auth.user?.permissions?.hr03_log ?? []
  // );

  const report_static_request_data = useSelector(
    (state) => state.auth.user?.permissions?.report_static_request_data ?? []
  );

  const report_static_org_external = useSelector(
    (state) => state.auth.user?.permissions?.report_static_org_external ?? []
  );

  const report_static_assign_case = useSelector(
    (state) => state.auth.user?.permissions?.report_static_assign_case ?? []
  );

  const isInternal = useSelector(
    (state) => state.auth.user?.isInternal ?? false
  );

  const render = () => {
    if (isAuthFetching || appLoading) {
      return <LoadingPage />;
    }

    if (maintenance) {
      return <MaPage />;
    }

    if (appInitSuccess === false) {
      return <InitFail />;
    }

    return (
      <Routes>
        <Route
          path="/login"
          element={
            <RedirectIfAuthenticate>
              <LoginPage />
            </RedirectIfAuthenticate>
          }
        />
        <Route
          path="/thaiId/login"
          element={
            <RedirectIfAuthenticate>
              <LoginPage />
            </RedirectIfAuthenticate>
          }
        />
        <Route
          path="/twofactor"
          element={
            <RedirectIfAuthenticate>
              <TwoFactorPage />
            </RedirectIfAuthenticate>
          }
        />
        <Route
          path="/google2fa"
          element={
            <RedirectIfAuthenticate>
              <Google2FAPage />
            </RedirectIfAuthenticate>
          }
        />
        <Route
          path="/register"
          element={
            <RedirectIfAuthenticate>
              <RegisterPage />
            </RedirectIfAuthenticate>
          }
        />
        <Route
          path="/vefify/email"
          element={
            <RedirectIfAuthenticate>
              <VerifyEmailPage />
            </RedirectIfAuthenticate>
          }
        />
        <Route
          path="/forgotpassword"
          element={
            <RedirectIfAuthenticate>
              <ForgotPasswordPage />
            </RedirectIfAuthenticate>
          }
        />
        <Route
          path="/forgotpassword/setpassword/:token"
          element={
            <RedirectIfAuthenticate>
              <ForgotSetPasswordPage />
            </RedirectIfAuthenticate>
          }
        />

        <Route
          path="/confirm/:activate_code"
          element={
            <RedirectIfAuthenticate>
              <UserActivationPage />
            </RedirectIfAuthenticate>
          }
        />
        <Route path="/admin/download/:token" element={<DowloadFilePage />} />
        <Route
          path="/forcechangepassword"
          element={
            <RedirectIfAuthenticate>
              <ForceSetPasswordPage />
            </RedirectIfAuthenticate>
          }
        />

        {/* Private routes */}
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Main />}>
            <Route path="/me" element={<ProfileViewEditPage />} />
            <Route path="/me/chpassword" element={<ChPassowrdPage />} />
            {setting_user.includes("list") && (
              <>
                <Route path="/settinguser" element={<SettingUser />} />
                {setting_user.includes("create") && (
                  <Route path="/user/add" element={<UserAddPage />} />
                )}
                {setting_user.includes("edit") ||
                setting_user.includes("view") ? (
                  <Route path="/user/edit/:id" element={<UserEditPage />} />
                ) : null}
              </>
            )}
            {/* <Route path="/settingusergroup" element={<SettingUserGroup />} /> */}

            {setting_external.includes("list") && (
              <>
                <Route path="/settinguserExternal" element={<ExternalUser />} />
                {setting_external.includes("create") && (
                  <Route
                    path="/userExternal/add"
                    element={<ExternalUserAdd />}
                  />
                )}
                {setting_external.includes("edit") ||
                setting_external.includes("view") ? (
                  <Route
                    path="/userExternal/edit/:id"
                    element={<ExternalUserEdit />}
                  />
                ) : null}
              </>
            )}

            {setting_permission.includes("list") && (
              <>
                <Route
                  path="/settingpermission"
                  element={<SettingPermission />}
                />
                {setting_permission.includes("create") && (
                  <Route
                    path="/permission/add"
                    element={<PermissionAddPage />}
                  />
                )}
                {setting_permission.includes("edit") ||
                setting_permission.includes("view") ? (
                  <Route
                    path="/permission/edit/:id"
                    element={<PermissionEditPage />}
                  />
                ) : null}
              </>
            )}
            {setting_police_station.includes("list") && (
              <>
                <Route path="/settingagency" element={<SettingAgency />} />
                {setting_police_station.includes("create") && (
                  <Route path="/agency/add" element={<AgencyAddPage />} />
                )}
                {setting_police_station.includes("edit") ||
                setting_police_station.includes("edit") ? (
                  <Route
                    path="/agency/edit/:org_code"
                    element={<AgencyEditPage />}
                  />
                ) : null}
              </>
            )}
            {setting_agency.includes("list") && (
              <>
                <Route
                  path="/settingchannel"
                  element={<SettingChannelSending />}
                />
                {setting_agency.includes("edit") ||
                setting_agency.includes("view") ? (
                  <Route
                    path="/settingchannel/edit/:id"
                    element={<ChannelSendingEditPage />}
                  />
                ) : null}
              </>
            )}
            {dashboard.includes("list") && (
              <Route path="/dashboard" element={<Dashboard />} />
            )}
            {monitor.includes("list") && (
              <Route path="/monitor" element={<Monitor />} />
            )}
            {setting_request.includes("list") && (
              <Route path="/settingrequest" element={<SettingRequest />} />
            )}
            {transaction_log.includes("list") && (
              <Route path="/transactionlog" element={<TransactionLog />} />
            )}
            {master_log.includes("list") && (
              <Route path="/masterlog" element={<MasterLog />} />
            )}
            {permission_log.includes("list") && (
              <Route path="/permissionlog" element={<PermissionLog />} />
            )}
            {user_log.includes("list") && (
              <Route path="/userlog" element={<UserLog />} />
            )}
            {notify_log.includes("list") && (
              <Route path="/notifylog" element={<NotifyLog />} />
            )}
            {/* <Route path="/organizationchart" element={<OrganizationChart />} /> */}
            {setting_config.includes("list") && (
              <Route path="/setting" element={<Notification />} />
            )}
            {HR03_filter.includes("list") && (
              <Route path="/hr03meeting" element={<HR03Meeting />} />
            )}
            <Route path="/line_callback" element={<LineCallbackPage />} />
            {cases.includes("list") && (
              <>
                <Route path="/cases" element={<CaseListPage />} />
                {cases.includes("create") && (
                  <Route path="/cases/add" element={<CaseAddPage />} />
                )}
                {cases.includes("view") && (
                  <>
                    <Route path="/cases/:id/mgt" element={<CaseMgtPage />} />
                    <Route
                      path="/cases/:id/requests/add"
                      element={<RequestAddPage />}
                    />
                    <Route
                      path="/cases/:id/requests/addH"
                      element={<RequestAddPageH />}
                    />
                    <Route
                      path="/cases/:id/requests/add/:type_id"
                      element={<RequestAddPage />}
                    />
                    <Route
                      path="/cases/:id/requests-telco/add"
                      element={<RequestTelcoAddPage />}
                    />
                    <Route
                      path="/cases/:id/requests/:reqId"
                      element={<RequestViewPage />}
                    />
                  </>
                )}
              </>
            )}
            {assign_case.includes("list") && (
              <Route path="/casesassign" element={<CaseAssignList />} />
            )}
            {/* <Route path="/caseswaitapprove" element={<CaseWaitApprove />} /> */}

            {report_static_request_data.includes("list") && (
              <Route path="/report/data" element={<DataReport />} />
            )}

            {report_static_org_external.includes("list") && (
              <Route path="/report/ext-data" element={<DataExternalReport />} />
            )}

            {report_static_assign_case.includes("list") && (
              <Route path="/report/assign" element={<AssignReport />} />
            )}

            {report_requests.includes("list") && (
              <Route path="/report/request" element={<RequestReport />} />
            )}
            {report_HR03_data.includes("list") && (
              <Route path="/report/hr03" element={<HR03Report />} />
            )}

            {report_static_assign_case.includes("list") && (
              <Route path="/report/usage" element={<UsageReport />} />
            )}

            {requests.includes("list") && (
              <>
                <Route
                  path="/requests"
                  element={<RequestListPage isPersonalPage />}
                />
                <Route
                  path="/requests/cases/:id/requests/:reqId"
                  element={<RequestViewAllPage />}
                />
              </>
            )}
            {/* <Route path="/noaccounts" element={<NomAccountListPage />} /> */}
            {search_account.includes("list") && (
              <Route path="/acc-search" element={<AccountSearchPage />} />
            )}
            {organize_request.includes("list") && (
              <Route
                path="/req-search"
                element={
                  // <RequireRoles roleIds={[2, 5]} navigate>
                  <RequestSearchPage />
                  // </RequireRoles>
                }
              />
            )}
            {saving_account_HR03.includes("list") && (
              <>
                <Route path="/hr03" element={<Hr03ListPage />} />
                {saving_account_HR03.includes("create") && (
                  <Route path="/hr03/add" element={<Hr03AccountAddPage />} />
                )}
                {saving_account_HR03.includes("edit") && (
                  <Route
                    path="/hr03/edit/:id"
                    element={<Hr03AccountEditPage />}
                  />
                )}
              </>
            )}
            {verify_account_HR03.includes("list") && (
              <Route
                path="/hr03/mgt"
                element={
                  // <RequireRoles roleIds={[2, 4]} navigate>
                  <Hr03MgtListPage />
                  // </RequireRoles>
                }
              />
            )}
            {request_account_HR03.includes("list") && (
              <>
                <Route
                  path="/hr03/request-active"
                  element={<Hr03RequestActive />}
                />
                {request_account_HR03.includes("create") && (
                  <Route
                    path="/hr03/request-active/add"
                    element={<Hr03RequestAddPage />}
                  />
                )}
                {(request_account_HR03.includes("view") ||
                  request_account_HR03.includes("edit")) && (
                  <Route
                    path="/hr03/request-active/edit/:id"
                    element={<Hr03RequestEditPage />}
                  />
                )}
              </>
            )}
            {upload_suspicious?.includes("list") && (
              <>
                <Route
                  path="/UploadSuspicious"
                  element={<UploadSuspicious />}
                />
                {upload_suspicious.includes("create") && (
                  <Route
                    path="/UploadSuspicious/add"
                    element={<UploadSuspiciousAdd />}
                  />
                )}
                {upload_suspicious.includes("view") && (
                  <Route
                    path="/UploadSuspicious/edit/:id"
                    element={<UploadSuspiciousEdit />}
                  />
                )}
              </>
            )}
            {manage_new_request.includes("list") &&
              all_request.includes("list") && (
                <Route
                  path="/admin/request-mgt"
                  element={
                    <RequireRoles roleIds={[2]} navigate>
                      <RequestListPage isAdminPage />
                    </RequireRoles>
                  }
                />
              )}

            {manage_new_request.includes("list") &&
              (new_request.includes("list") ||
                approved_request.includes("list") ||
                approved_request_group.includes("list") ||
                template_group.includes("list")) && (
                <Route
                  path="/admin/request-mgt/:status"
                  element={
                    <RequireRoles roleIds={[2]} navigate>
                      <RequestListPage isAdminPage />
                    </RequireRoles>
                  }
                />
              )}
            {approved_request_group.includes("list") && (
              <Route
                path="admin/request-mgt/checked-sum"
                element={
                  <RequireRoles roleIds={[2]} navigate>
                    <CheckedSumRequestPage />
                  </RequireRoles>
                }
              />
            )}
            {template_group.includes("list") && (
              <Route
                path="admin/request-mgt/sum-req"
                element={
                  <RequireRoles roleIds={[2]} navigate>
                    <SumRequestPage />
                  </RequireRoles>
                }
              />
            )}
            {template_upload.includes("list") && (
              <Route
                path="/admin/responses/upload"
                element={
                  <RequireRoles roleIds={[2]} navigate>
                    <ResponseUploadPage />
                  </RequireRoles>
                }
              />
            )}

            {(extorg_request_list.includes("list") || !isInternal) && (
              <>
                <Route path="/requestletter" element={<RequestLetterList />} />
                {(extorg_request_list.includes("view") || !isInternal) && (
                  <Route
                    path="/requestletter/:id/requests/:reqId"
                    element={<RequestViewPage />}
                  />
                )}
              </>
            )}
            {(extorg_request_list.includes("upload") ||
              extorg_template_upload.includes("list") ||
              extorg_template_upload.includes("upload") ||
              !isInternal) && (
              <Route
                path="/external/responses/upload"
                element={
                  // <RequireRoles roleIds={[2]} navigate>
                  <ResponseUploadPage isExternal />
                  // </RequireRoles>
                }
              />
            )}
            {file_normalization.includes("list") && (
              <Route path="/admin/file-normalize" element={<UploadPage />} />
            )}

            {/* <Route path="/" element={<Navigate to="/first" replace />} /> */}
            {/* <Route path="*" element={<Navigate to="/notfound" replace />} /> */}
          </Route>
          {/* Not Found Route */}
          <Route path="*" element={<Notfound />} />
        </Route>
      </Routes>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={thLocale}>
      <ThemeProvider theme={mdTheme}>
        <BrowserRouter>
          <Modal
            open={(password_expired_alert || password_is_expire) ?? false}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              <Grid>
                <Grid mb={3}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    แจ้งเตือน
                  </Typography>
                  <Typography id="modal-modal-title" variant="p">
                    {password_is_expire ? (
                      <>
                        รหัสผ่านของท่านหมดอายุแล้ว
                        <br />
                        กรุณา Login ใหม่อีกครั้งเปลี่ยนรหัสผ่านใหม่
                      </>
                    ) : password_expired_alert ? (
                      <>
                        รหัสผ่านของท่านใกล้หมดอายุแล้ว
                        <br />
                        กรุณาเปลี่ยนรหัสผ่านใหม่
                        เพื่อสามารถเข้าใช้งานได้อย่างต่อเนื่อง
                      </>
                    ) : (
                      ""
                    )}
                  </Typography>
                </Grid>
                <Grid>
                  {password_is_expire ? (
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={() => dispatch(authLogout())}
                    >
                      ตกลง
                    </Button>
                  ) : password_expired_alert ? (
                    <Button
                      component={Link}
                      to="/me/chpassword"
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        dispatch({
                          type: AUTH_PASSWORD_EXPIRED_ALERT,
                          payload: false,
                        })
                      }
                    >
                      ตกลง
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Box>
          </Modal>

          {render()}
        </BrowserRouter>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
