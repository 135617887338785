import { FORM_ERROR } from "final-form";
import Joi from "joi";
import React, { useEffect, useState } from "react";
import validationMsg from "../../../helpers/validationMsg";
import _ from "lodash";
import {
  Alert,
  Button,
  Divider,
  Grid,
  Stack,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
import FieldInput from "../../form-fields/FieldInput";
import FieldAsyncSelect from "../../form-fields/FieldAsyncSelect";
import SubmitBtn from "../../SubmitBtn";
import { Save } from "@mui/icons-material";
import bankAccountTypes from "../../../data/selects/bankAccountTypes";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";
import { getTextMask } from "../../../helpers/imask";
import { Field } from "react-final-form";

const TextMaskCustom = getTextMask({
  mask: "00000000000000",
  unmask: true,
  overwrite: true,
});

export default function AccountForm({
  readOnly = false, // mycustom
  handleCancel, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  setChannel,
}) {
  const [type, setType] = useState(1);
  const handleChannel = (e) => {
    const val = +e.target.value;
    setType(val);
    setChannel(val);
    form.change("channel", val);
  };

  useEffect(() => {
    setType(values.channel);
  }, [values]);
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2}>
        {submitFailed && (error || submitError) && (
          <Grid item xs={12}>
            <Alert severity="warning" sx={{ width: "auto" }}>
              {error || submitError}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Field name="channel" defaultValue={1}>
            {({ input, meta }) => (
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue={input.value ? +input.value : 1}
                  onChange={handleChannel}
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="ธนาคาร"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="พร้อมเพย์"
                  />
                  <FormControlLabel
                    value={3}
                    control={<Radio />}
                    label="วอลเล็ต"
                  />
                </RadioGroup>
              </FormControl>
            )}
          </Field>
        </Grid>

        <Grid item xs={12}>
          <FieldAsyncSelect
            name="bank_id"
            label="ธนาคาร"
            required
            controlProp={{ fullWidth: true }}
            readOnly={readOnly}
            basePath={`/api/banks/list/bank`}
            // filter={{ search: "xxxx"}}
            itemFormat={(row) => {
              return {
                value: row.id,
                label: `${row.name} (${row.short_name})`,
              };
            }}
            inputProps={{ sx: { width: "100%" } }}
          />
        </Grid>
        {type === 1 && (
          <>
            <Grid item xs={12} md={6}>
              <FieldInput
                name="number"
                label="หมายเลขบัญชี"
                required
                controlProps={{ fullWidth: true }}
                readOnly={readOnly}
                inputProps={{
                  inputComponent: TextMaskCustom,
                }}
              />
            </Grid>
          </>
        )}
        {type === 2 && (
          <>
            <Grid item xs={12} md={6}>
              <FieldInput
                name="promptpay"
                label="พร้อมเพย์"
                // required
                controlProps={{ fullWidth: true }}
                readOnly={readOnly}
                inputProps={{
                  inputComponent: TextMaskCustom,
                }}
              />
            </Grid>
          </>
        )}
        {type === 3 && (
          <>
            <Grid item xs={12} md={6}>
              <FieldInput
                name="wallet"
                label="วอลเล็ต"
                // required
                controlProps={{ fullWidth: true }}
                readOnly={readOnly}
                inputProps={{
                  inputComponent: TextMaskCustom,
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={6}>
          <FieldInput
            name="name"
            label="ชื่อบัญชี"
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldAutoComplete
            name="type_id"
            label="ประเภท"
            options={bankAccountTypes}
            readOnly={readOnly}
            controlProp={{ fullWidth: true }}
            // required
            // disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="seq"
            label="ลำดับการรับเงิน"
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
            inputType="number"
          />
        </Grid>
        <Grid item xs={12}>
          <Divider></Divider>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <FieldAutoComplete
            name="freeze_status"
            label="สถานะการอายัด"
            options={[{ value: 1, label: "อายัด" }]}
            readOnly={readOnly}
            controlProp={{ fullWidth: true }}
            // required
            // disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            inputType="number"
            name="balance"
            label="ยอดเงินคงเหลือ"
            controlProps={{ fullWidth: true }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FieldInput
            name="card_id"
            label="เลขบัตรประจำตัวประชาชน หรือ passport"
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="tel"
            label="หมายเลขโทรศัพท์"
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid> */}
        <Grid item xs={12}>
          <FieldInput
            name="notes"
            label="หมายเหตุ"
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        {!readOnly && (
          <>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <SubmitBtn
                  variant="contained"
                  startIcon={<Save />}
                  submitting={submitting}
                  pristine={pristine}
                >
                  บันทึก
                </SubmitBtn>
                <Button
                  variant="outlined"
                  disabled={submitting}
                  onClick={handleCancel}
                >
                  ยกเลิก
                </Button>
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
}

export const validate = (values, props) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  // console.log(vResult);
  // console.log(schema);

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  // console.log(errors);

  return errors;
};

export const validateRules = {
  channel: Joi.number().messages({
    "any.required": "กรุณาเลือกช่องทางการรับเงิน",
    }).required(),
  number: Joi.string()
    .pattern(/^\d{10,}$/)
    .max(14)
    .messages({
      "string.pattern.base": "หมายเลขบัญชีต้องเป็นตัวเลข 10 ถึง 14 หลักเท่านั้น",
    })
    .allow(null),
  
  bank_id: Joi.number().messages({
    "string.pattern.base": "กรุณาระบุธนาคาร",
    "any.required": "กรุณาระบุธนาคาร",
  }).required(),
  name: Joi.string().max(255).allow(null),
  type_id: Joi.number().allow(null),
  seq: Joi.number().min(0).allow(null),
  promptpay: Joi.string()
    .pattern(/^(\d{10}|\d{13})$/)
    .messages({
      "string.pattern.base": "หมายเลขพร้อมเพย์ต้องเป็นเบอร์โทรศัพท์ (10 หลัก) หรือเลขบัตรประชาชน (13 หลัก)",
    })
    .allow(null),
  wallet: Joi.string()
    .pattern(/^\d{10,}$/)
    .messages({
      "string.pattern.base": "หมายเลขกระเป๋าเงินต้องเป็นเบอร์โทรศัพท์ 10 หลัก",
    })

    .allow(null),
  notes: Joi.string().max(20).allow(null),
};

// const schema = Joi.object(validateRules).error(validationMsg());
const schema = Joi.object(validateRules)
