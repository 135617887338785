import { Check } from "@mui/icons-material";
import {
  Alert,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { isAfter, parseISO } from "date-fns";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useMemo } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { defaultJoiDates } from "../../../../helpers/dateHelper";
import validationMsg from "../../../../helpers/validationMsg";
import SubmitBtn from "../../../SubmitBtn";
import FieldAutoComplete from "../../FieldAutoComplete";
import FieldDatePicker from "../../FieldDatePicker";
import FieldInput from "../../FieldInput";

export default function CctvForm({
  onSubmit,
  handleCloseModal,
  header,
  initialValues,
}) {
  const reqBank = useSelector((state) => state.req18e.reqBank);

  const preAccounts = useSelector((state) => state.req18f.preAccounts);

  const preAccountOptions = useMemo(() => {
    if (preAccounts && preAccounts.sortBankAccounts) {
      return (
        [...preAccounts?.sortBankAccounts].filter(
          (e) => e.value === preAccounts?.select
        ) ?? []
      );
    }
    return preAccounts.map((e) => ({
      value: e.id,
      label: `${e.bank.short_name}:${e.number}:${e.name}`,
    }));
  }, [preAccounts]);

  return (
    <>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {header}
      </Typography>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Form
          validate={validate}
          onSubmit={onSubmit}
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            errors,
            error,
            submitError,
            submitting,
            pristine,
            initialValues,
            submitFailed,
            form,
            values,
          }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  {submitFailed && (error || submitError) && (
                    <Grid item xs={12}>
                      <Alert severity="warning" sx={{ width: "auto" }}>
                        {error || submitError}
                      </Alert>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="info.main"
                      gutterBottom
                    >
                      {`${reqBank?.name ?? ""}(${reqBank?.short_name ?? ""})`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FieldAutoComplete
                      name="bank_account_id"
                      label="บัญชี"
                      required
                      controlProp={{ fullWidth: true }}
                      inputProps={{ sx: { width: "100%" } }}
                      options={preAccountOptions ?? []}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FieldInput
                      name="branch_name"
                      label="สาขา"
                      required
                      controlProps={{ fullWidth: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FieldDatePicker
                      name="from_date"
                      label="ตั้งแต่วันที่ เวลา"
                      required
                      pickerProps={{
                        inputFormat: "dd/MM/yyyy",
                        disableFuture: true,
                        openTo: "year",
                        views: ["year", "month", "day"],
                        allowSameDateSelection: true,
                      }}
                      readOnly={false}
                    />
                  
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                 
                  <FieldDatePicker
                      name="to_date"
                      label="ถึงวันที่ เวลา"
                      required
                      pickerProps={{
                        inputFormat: "dd/MM/yyyy",
                        disableFuture: true,
                        openTo: "year",
                        views: ["year", "month", "day"],
                        allowSameDateSelection: true,
                      }}
                      readOnly={false}
                    />
                 
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Divider></Divider>
                  </Grid>
                  <Grid item md={12}>
                    <Stack direction="row" spacing={2}>
                      <SubmitBtn
                        variant="contained"
                        startIcon={<Check />}
                        submitting={submitting}
                        pristine={pristine}
                      >
                        ตกลง
                      </SubmitBtn>
                      <Button
                        type="button"
                        variant="outlined"
                        disabled={submitting}
                        onClick={() => {
                          handleCloseModal();
                        }}
                      >
                        ยกเลิก
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Form>
      </Paper>
    </>
  );
}

const validate = (values) => {
  const errors = {};
  const vResult = schema().validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  if (
    !errors.from_date &&
    !errors.to_date &&
    isAfter(parseISO(values.from_date), parseISO(values.to_date))
  ) {
    errors.from_date = "ไม่ถูกต้อง";
    errors.to_date = "ไม่ถูกต้อง";
  }

  return errors;
};

export const validateRules = () => ({
  bank_account_id: Joi.number().min(1).required(),
  from_date: Joi.date()
    .min(defaultJoiDates.minDateTime)
    .max(new Date())
    .required(),
  to_date: Joi.date()
    .min(defaultJoiDates.minDateTime)
    .max(new Date())
    .required(),
  branch_name: Joi.string().trim().max(200).required(),
});

const schema = () =>
  Joi.object(validateRules()).error(
    validationMsg({
      dateFormat: {
        from_date: "dd/MM/yyyy HH:mm",
        to_date: "dd/MM/yyyy HH:mm",
      },
    })
  );
