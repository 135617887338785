import { Alert, Grid } from "@mui/material";
import React,{useEffect} from "react";
import FieldInput from "../../form-fields/FieldInput";

export default function TelephoneHistoryForm({
  readOnly = false, // mycustom
  handleCancel, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  data
}) {

  useEffect(() => {
    if(data){
      form.change("phone_number",data?.phone_number);
      form.change("type",data?.type?.type);
      form.change("status",data?.freeze_status);
      form.change("provider",`${data?.bank.name} (${data?.bank.short_name})`);
      form.change("status",data?.freeze_status);

    }
  }, [data])
  

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2}>
        {submitFailed && (error || submitError) && (
          <Grid item xs={12}>
            <Alert severity="warning" sx={{ width: "auto" }}>
              {error || submitError}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <FieldInput
            name="provider"
            label="ค่ายมือถือ"
            controlProps={{ fullWidth: true }}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="phone_number"
            label="เบอร์มือถือ"
            controlProps={{ fullWidth: true }}
            readOnly={true}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FieldInput
            name="type"
            label="ประเภท"
            controlProps={{ fullWidth: true }}
            readOnly={true}
          />
        </Grid>
    
        <Grid item xs={12} md={6}>
          <FieldInput
            name="status"
            label="สถานะอายัด"
            controlProps={{ fullWidth: true }}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </form>
  );
}

// export const validate = (values, props) => {
//   const errors = {};
//   const vResult = schema.validate(values, {
//     abortEarly: false,
//     allowUnknown: false,
//   });

//   // console.log(vResult);
//   // console.log(schema);

//   if (vResult.error) {
//     let details = vResult.error.details;
//     details.forEach(({ context, message }) => {
//       _.set(errors, context.label, message);
//       // return (errors[context.label] = message);
//       errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
//     });
//   }

//   // console.log(errors);

//   return errors;
// };

// export const validateRules = {
//   number: Joi.string()
//     .pattern(/^\d{10,}$/)
//     .max(12)
//     .required(),
//   bank_id: Joi.number().required(),
//   name: Joi.string().max(255).allow(null),
//   type_id: Joi.number().allow(null),
//   seq: Joi.number().min(0).allow(null),
//   // balance: Joi.number().min(0).allow(null),
//   // freeze_status: Joi.number().allow(null, 1),
//   // tel: Joi.string().allow(null),
//   // card_id: Joi.string().max(100).allow(null),
//   note: Joi.string().max(20).allow(null),
// };

// const schema = Joi.object(validateRules).error(validationMsg());
