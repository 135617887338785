import api from "../apis";

const INIT = "req18f/INIT";
const INIT_ROWS = "req18f/INIT_ROWS";
const INIT_ERROR = "req18f/INIT_ERROR";
const ADD_ROW = "req18f/ADD_ROW";
const SET_ROW = "req18f/SET_ROW";
const REMOVE_ROW = "req18f/REMOVE_ROW";
const SET_LOADING = "req18f/SET_LOADING";

const CHANGE_PAGE_SIZE = "req18f/CHANGE_PAGE_SIZE";

const CLEANUP = "req18f/CLEANUP";

const defaultState = {
  reqBank: null,
  allBanks: [],
  preAccounts: [],
  loading: true,
  error: false,
  grid: {
    rows: [],
    loading: false,
    pageSize: 10,
  },
};

// Reducer
export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case CLEANUP:
      return { ...defaultState };
    case CHANGE_PAGE_SIZE:
      return {
        ...state,
        grid: {
          ...state.grid,
          pageSize: action.payload,
        },
      };
    case INIT_ERROR:
      return { ...state, loading: false, error: true };
    case INIT_ROWS:
      return {
        ...state,
        loading: false,
        grid: { ...state.grid, rows: action.payload },
      };
    case INIT:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ADD_ROW:
      const addedRows = [
        ...state.grid.rows,
        { ...action.payload, id: state.grid.rows.length + 1 },
      ];
      return { ...state, grid: { ...state.grid, rows: addedRows } };
    case SET_ROW:
      const rows = state.grid.rows.map((r) =>
        r.id === action.payload.id
          ? { ...action.payload.row, id: action.payload.id }
          : r
      );
      return { ...state, grid: { ...state.grid, rows } };
    case REMOVE_ROW:
      const removed = state.grid.rows.filter((e) => e.id !== action.payload);
      return { ...state, grid: { ...state.grid, rows: removed } };
    default:
      return state;
  }
}

export const changePageSize = (pageSize) => {
  return { type: CHANGE_PAGE_SIZE, payload: pageSize };
};

// for read only mode
export const initOnlyRows = (initRows) => ({
  type: INIT_ROWS,
  payload: initRows,
});

export const init = (bank_id) => async (dispatch) => {
  let response = null;
  let payload = {};
  try {
    response = await api.get(`/api/banks/${bank_id}`);
    payload.reqBank = response.data;
    response = await api.get(`/api/banks`, { params: { type: "bank" } });
    payload.allBanks = response.data.rows;
    dispatch({ type: INIT, payload });
  } catch (e) {
    dispatch({ type: INIT_ERROR });
    throw e;
  }
};

export const initAccounts = (bank_id, caseId) => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let response = null;
  let payload = {};
  try {
    response = await api.get(`/api/banks/${bank_id}`);
    payload.reqBank = response.data;
    response = await api.get(`/api/cases/${caseId}/bank_accounts`);
    payload.preAccounts = response.data.rows.filter(
      (e) => e.bank_id === bank_id
    );
    dispatch({ type: INIT, payload });
    dispatch({ type: SET_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: INIT_ERROR });
    throw e;
  }
};

export const preAccountsActions = (account) => async (dispatch) => {
  dispatch({ type: INIT, payload: { preAccounts: account } });
};

export const addRow = (row) => ({ type: ADD_ROW, payload: row });

export const setRow = (row, id) => ({
  type: SET_ROW,
  payload: { row, id },
});

export const removeRow = (id) => ({
  type: REMOVE_ROW,
  payload: id,
});

export const cleanup = () => ({ type: CLEANUP });
