import { Refresh } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Grid, Modal, Paper, Snackbar, Typography } from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import {
  notifyAuditChangePage,
  notifyAuditChangePageSize,
  notifyAuditClearResult,
  notifyAuditFetch,
  notifyAuditForceReload,
  notifyAuditSetFilter,
} from "../../../../ducks/auditList";
import NotifyLogSearchForm from "./NotifyLogSearchForm";

export default function SettingNotifyLog() {
  const dispatch = useDispatch();
  const { status: reqStatus } = useParams();
  const isValidStatus = useMemo(() => {
    return (
      reqStatus === undefined ||
      ["sent_to_contact_person", "checked"].includes(reqStatus)
    );
  }, [reqStatus]);

  useEffect(() => {
    return () => {
      dispatch(notifyAuditClearResult());
      dispatch(notifyAuditSetFilter({}));
    };
  }, [dispatch, reqStatus]);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const fetchIdRef = React.useRef(0);
  const rows = useSelector((state) => state?.auditList?.notify_audit?.data);
  const total = useSelector(
    (state) => state?.auditList?.metaNotifyAudit?.total
  );
  const loading = useSelector(
    (state) => state?.auditList?.metaNotifyAudit?.loading
  );
  const forceReload = useSelector(
    (state) => state?.auditList?.metaNotifyAudit?.forceReload
  );
  const page = useSelector((state) => state?.auditList?.metaNotifyAudit?.page);
  const pageSize = useSelector(
    (state) => state?.auditList?.metaNotifyAudit?.pageSize
  );
  const filter = useSelector(
    (state) => state?.auditList?.metaNotifyAudit?.filter
  );

  useEffect(() => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    //filter overide by url status
    const activeFilter = reqStatus ? { ...filter, status: reqStatus } : filter;
    // Only update the data if this is the latest fetch
    dispatch(
      notifyAuditFetch(pageSize, page, activeFilter, fetchId, fetchIdRef)
    ).catch((e) => {
      SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, forceReload, filter]);

  // add modal state
  const [openModal, setOpenModal] = React.useState(false);
  // const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = useCallback(
    (reason) => {
      if (reason) {
        if (reason.type === "error") {
          SetToastInfo(reason);
        } else {
          dispatch(notifyAuditForceReload());
        }
      }
      setOpenModal(false);
    },
    [dispatch]
  );

  const columns = [
    {
      field: "id",
      headerName: "No",
      sortable: false,
      width: 70,
    },
    // {
    //   field: "request_id",
    //   headerName: "request_ID",
    //   sortable: false,
    //   width: 70,
    // },
    {
      field: "event_label",
      headerName: "Event",
      sortable: false,
      width: 200,
    },
    {
      field: "external_name",
      headerName: "หน่วยงาน",
      sortable: false,
      width: 100,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      width: 100,
    },
    {
      field: "tag",
      headerName: "Tag",
      sortable: false,
      width: 100,
    },
    {
      field: "message",
      headerName: "Message",
      sortable: false,
      width: 500,
    },
    {
      field: "created_at",
      headerName: "วันที่",
      sortable: false,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm") : undefined;
      },
    },
  ];

  const onSearchSubmit = (formValues) => {
    dispatch(notifyAuditSetFilter(formValues));
  };

  if (!isValidStatus) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
      <Grid item xs={12}>
        <Typography variant="h5">Notify Log Webhook</Typography>
        <Typography variant="p">
          Log สำหรับเก็บประวัติที่เกี่ยวกับ Notify
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 800,
            width: "100%",
          }}
        >
          <Form
            // validate={validate}
            onSubmit={onSearchSubmit}
            component={NotifyLogSearchForm}
            loading={loading}
            onReset={() => dispatch(notifyAuditSetFilter({}))}
            showStatusField={reqStatus ? false : true}
          />
          <DataGrid
            // density="compact"
            disableColumnMenu
            columns={columns}
            rows={rows ?? []}
            rowCount={total}
            loading={loading}
            rowsPerPageOptions={[5, 10, 15, 20]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(page) => dispatch(notifyAuditChangePage(page))}
            onPageSizeChange={(pageSize) =>
              dispatch(notifyAuditChangePageSize(pageSize))
            }
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Paper>
      </Grid>
    </>
  );
}

function CustomToolbar() {
  const dispatch = useDispatch();
  const loading = useSelector(
    (state) => state?.auditList?.metaNotifyAudit?.loading
  );

  return (
    <GridToolbarContainer>
      <LoadingButton
        size="small"
        variant="outlined"
        color="info"
        loading={loading}
        onClick={() => dispatch(notifyAuditForceReload())}
        startIcon={<Refresh />}
      >
        Reload
      </LoadingButton>
    </GridToolbarContainer>
  );
}
