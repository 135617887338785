import axios from "axios";
import _ from "lodash";
import { init, maintenance } from "../ducks/appData";
import { authLogout } from "../ducks/auth";

// Declare the store variable
let store = null;

// Create an axios instance with the specified base URL and headers
const mainAxios = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_PROD_URL
      : process.env.REACT_APP_API_DEV_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "ngrok-skip-browser-warning": null,
  },
});

mainAxios.interceptors.request.use(
  (config) => config,
  (error) => {
    return Promise.reject(error instanceof Error ? error : new Error(error));
  }
);
// Set up an interceptor to handle responses and errors
mainAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    originalRequest.headers.Authorization &&
      mainAxios.post("/api/error_hook", {
        api_url:originalRequest.baseURL+originalRequest.url ,
        method: originalRequest.method.toLocaleUpperCase(),
        error_code: error.response?.status,
        error_message: error.message,
      });

    // Check if the error occurred during login
    if (originalRequest.url === "/api/login") {
      return Promise.reject(error instanceof Error ? error : new Error(error));
    }

    // Handle 401 Unauthorized errors by logging out the user
    if (
      error.response?.status === 401 &&
      originalRequest.url !== "/api/verify_otp"
    ) {
      store.dispatch(authLogout());
      localStorage.clear();
      sessionStorage.clear();
      // redirect to login
      window.location.href = "/login";
    }

    // Handle 503 Service Unavailable errors by dispatching a maintenance action
    if (error.response?.status === 503) {
      store.dispatch(maintenance(error.response.data?.message));
    }

    return Promise.reject(error instanceof Error ? error : new Error(error));
  }
);

// Initialize the API by storing the Redux store and dispatching the init action
export const initApi = (reduxStore) => {
  store = reduxStore;
  store.dispatch(init());
};

// Export the main axios instance as the default export
export default mainAxios;

// Export a function to extract relevant error response information
export const errorResponse = (error) => {
  return _.pick(error.response, ["status", "statusText", "data"]);
};

// Export a function to set the Authorization token in the axios instance
export const setAuthorizationToken = (token) => {
  if (token) {
    mainAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete mainAxios.defaults.headers.common["Authorization"];
  }
};
