import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Skeleton,
  Stack,
  Typography,
  ButtonGroup,
  Snackbar,
  Alert,
  Link,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import api from "../../../apis";
import { ChevronLeft, ChevronRight, Download } from "@mui/icons-material";
import fileDownload from "js-file-download";
import { LoadingButton } from "@mui/lab";
import { Buffer } from "buffer";


const masterModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  height: "100%",
};

export default function RequestSendConfirm({
  open,
  onClose,
  request,
  onConfirm,
}) {
  const [pdfFile, setPdfFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const pdfContainer = useRef();
  const pdfContainerLoadingWidth = useRef();
  const [downloaded, setDownloaded] = useState(false);
  const handleDownload = () => {
    const a = document.createElement("a");
    a.download = pdfFile.filename;
    a.href = pdfFile.data;
    a.click();
  };

  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = () => {
    setSubmitting(true);
    api
      .put(
        `/api/mgt/cases/${request.case_id}/pol_requests/${request.id}/sent_to_contact_person`
      )
      .then(() => {
        onConfirm();
        onClose();
      })
      .catch(({ response }) => {
        SetFetchError("เกิดข้อผิดพลาด");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  // for fetch error
  const [fetchError, SetFetchError] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchError(false);
  };

  // downloaded button effect
  useEffect(() => {
    let active = true;

    if (!downloaded) {
      return undefined;
    }

    setTimeout(() => {
      active && setDownloaded(false);
    }, 5000);

    return () => {
      active = false;
    };
  }, [downloaded]);

  const rednderPagination = () => {
    if (numPages === null) {
      return undefined;
    }


    return (
      <ButtonGroup
        variant="outlined"
        aria-label="outlined primary button group"
      >
        <Button
          onClick={() => {
            pdfContainerLoadingWidth.current = pdfContainer.current.offsetWidth;
            setPageNumber((prev) => prev - 1);
          }}
          disabled={pageNumber <= 1}
        >
          <ChevronLeft />
        </Button>
        <Button
          onClick={() => {
            pdfContainerLoadingWidth.current = pdfContainer.current.offsetWidth;
            setPageNumber((prev) => prev + 1);
          }}
          disabled={pageNumber >= numPages}
        >
          <ChevronRight />
        </Button>
      </ButtonGroup>
    );
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let active = true;

    if (!open) {
      setPdfFile(null);
      setPageNumber(1);
      //       setNumPages(null);
      return undefined;
    }

    setLoading(true);

    if (!request?.links?.length) {
      setLoading(false);
      return undefined;
    }

    const pdfLink = request.links.find((link) => link.name === "export_pdf");

    if (!pdfLink?.link) {
      setLoading(false);
      return undefined;
    }

    api
      .get(pdfLink.link, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const contentDisposition = response.headers["content-disposition"];
        const filename = /filename=".+"/.exec(contentDisposition)
          ? decodeURI(/filename="(.+)"/.exec(contentDisposition)[1])
          : "download";

        // const base64 = Buffer.from(response.data).toString("base64");
        const buffer = Buffer.from(response.data);
        const base64String = buffer.toString("base64");
        active &&
          setPdfFile({
            filename,
            data: `data:${response.headers["content-type"]};base64,${base64String}`,
          });
        active && setLoading(false);
      })
      .catch((e) => {
        if (e.response) {
          SetFetchError("เกิดข้อผิดพลาด");
        } else if (e.request) {
          // The request was made but no response was received or blocked by idm
          active && setPdfFile({ error: "ไม่สามารถแสดงหนังสือคำขอได้" });
          active && setLoading(false);
        } else {
          SetFetchError("เกิดข้อผิดพลาด");
        }
      });

    return () => {
      active = false;
    };
  }, [request.links, open]);

  // useEffect(() => {
    
  //   // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  //   pdfjs.GlobalWorkerOptions.workerSrc = '/pdfjs/pdf.worker.js';

  // }, [pdfjs]);

  const modalStyle = {
    ...masterModalStyle,
    height: pdfFile?.filename ? "100%" : undefined,
    // width: pdfContainerLoadingWidth.current
    //   ? `${pdfContainerLoadingWidth.current}px`
    //   : "auto",
    width: "auto",
  };

  useEffect(() => {
    console.log("pdfFile?.data=>>>",pdfFile);
    
  }, [pdfFile])
  

  return (
    <>
      <Snackbar
        open={fetchError ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {fetchError}
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle} ref={pdfContainer}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            ยืนยันการส่งคำขอ
          </Typography>
          <Typography variant="subtitle1" color="warning.main" gutterBottom>
            กรุณาตรวจสอบข้อมูลก่อนส่งไปยัง admin
          </Typography>
          {loading ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <>
              {pdfFile?.filename && (
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    mb: 2,
                  }}
                >
                  <Grid container spacing={2}>
                    <>
                      <Grid item xs={12} sx={{ textAlign: "right" }}>
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Button
                            variant="contained"
                            color="info"
                            size="small"
                            startIcon={<Download />}
                            onClick={() => {
                              setDownloaded(true);
                              handleDownload()
                              // fileDownload(pdfFile.data, pdfFile.filename);
                            }}
                            disabled={downloaded}
                          >
                            ดาวน์โหลด
                          </Button>
                          {rednderPagination()}
                          <Typography variant="subtitle1">
                            Page {pageNumber} of {numPages}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Document
                          file={pdfFile?.data}
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          <Page
                            pageNumber={pageNumber}
                            scale={
                              pdfContainer?.current?.offsetWidth >= 338
                                ? 1.4
                                : 1.0
                            }
                            onLoadSuccess={() => {
                              pdfContainerLoadingWidth.current = null;
                            }}
                          />
                        </Document>
                      </Grid>
                    </>
                  </Grid>
                </Paper>
              )}
              <Stack direction="row" spacing={2}>
                <LoadingButton
                  type="button"
                  variant="contained"
                  loading={submitting}
                  onClick={handleSubmit}
                >
                  ยืนยัน
                </LoadingButton>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => {
                    onClose();
                  }}
                >
                  ยกเลิก
                </Button>
              </Stack>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
}
