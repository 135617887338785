import {
  AccountBalance,
  Delete,
  Download,
  ForwardToInbox,
  SendToMobile,
  Visibility,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../../apis";
import { getChip } from "../../../data/requestStatus";
import {
  crqClearResult,
  crqFetch,
  crqMarkLoading,
  crqSetFilter,
  forceReload as forceReloadRequests,
} from "../../../ducks/caseRequestList";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import RequestListSearchForm from "./RequestListSearchForm";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "900px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function RequestListView() {
  const cases = useSelector((state) => state.auth.user.permissions.cases ?? []);
  const requests = useSelector(
    (state) => state.auth.user.permissions.requests ?? []
  );
  const params = useParams();
  const navigate = useNavigate();
  const caseId = parseInt(params.id);

  const [anchorElDowload, setAnchorElDowload] = React.useState(null);
  const [anchorElAddBankRequest, setAnchorElAddBankRequest] =
    React.useState(null);
  const openDowload = Boolean(anchorElDowload);
  const openAddBankRequest = Boolean(anchorElAddBankRequest);
  const [isError, setIsError] = React.useState(false);

  const handleClickDowload = (event) => {
    setAnchorElDowload(event.currentTarget);
  };

  const handleCloseDownload = () => {
    setAnchorElDowload(null);
  };
  const handleCloseAddBankRequest = () => {
    setAnchorElAddBankRequest(null);
  };
  const handleOpenAddBankRequest = (event) => {
    setAnchorElAddBankRequest(event.currentTarget);
  };

  const columns = [
    ...(requests.includes("view") //||cases.includes("delete")
      ? [
          {
            field: "actions",
            headerName: "actions",
            sortable: false,
            width: 100,
            renderCell: (params) => {
              return (
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="left"
                  sx={{ width: "100%" }}
                >
                  {requests.includes("view") && (
                    <IconButton
                      component={Link}
                      to={`/cases/${caseId}/requests/${params.id}`}
                      type="button"
                      variant="contained"
                      size="small"
                      // onClick={() => {
                      //   handleOpenModal(
                      //     <RequestView
                      //       handleCancel={handleCloseModal}
                      //       requestId={params.id}
                      //     />
                      //   );
                      // }}
                    >
                      {params.row.status === "new" ? (
                        <ForwardToInbox color="primary" />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  )}
                  {requests.includes("delete") &&
                    ["new", "reject"].includes(params.row.status) && (
                      <IconButton
                        type="button"
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={() => {
                          setCfDel(params.id);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    )}
                </Stack>
              );
            },
          },
        ]
      : []),
    { field: "id", headerName: "ID", sortable: false, maxWidth: 70 },
    {
      field: "status",
      headerName: "สถานะ",
      sortable: false,
      minWidth: 180,
      maxWidth: 180,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            {getChip(params.row.status, { size: "small" })}{" "}
            {params.row.status === "new" && (
              <Chip
                label="กรุณายืนยัน"
                color="warning"
                size="small"
                sx={{
                  height: "auto",
                  "& .MuiChip-label": {
                    display: "block",
                    whiteSpace: "normal",
                    fontFamily: "Kanit",
                  },
                }}
              />
            )}
          </Box>
        );
      },
    },
    {
      field: "detail_id",
      headerName: "setid",
      sortable: false,
      maxWidth: 70,
    },
    {
      field: "request_type.name",
      headerName: "ประเภทคำขอ",
      sortable: false,
      minWidth: 250,
      maxWidth: 300,
      valueGetter: (params) => {
        return params.row.request_type?.name;
      },
    },
    {
      field: "bank.name",
      headerName: "หน่วยงาน",
      sortable: false,
      width: 100,
      valueGetter: (params) => {
        return params.row.bank?.short_name;
      },
    },
    {
      field: "created_at",
      headerName: "วันที่ขอ",
      sortable: false,
      width: 150,
      valueGetter: (params) => {
        if (!params.row.created_at) {
          return undefined;
        }

        const date = parseISO(params.row.created_at);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm") : undefined;
      },
    },
    {
      field: "description",
      headerName: "ข้อมูลที่ร้องขอ",
      sortable: false,
      minWidth: 250,
      maxWidth: 300,
    },
    {
      field: "detail",
      headerName: "รายละเอียด",
      sortable: false,
      minWidth: 150,
      maxWidth: 200,
      valueGetter: (params) => {
        return params.row.detail?.no;
      },
    },
    {
      field: "status_note",
      headerName: "หมายเหตุ",
      sortable: false,
      minWidth: 200,
      maxWidth: 250,
    },
  ];

  const dispatch = useDispatch();
  const fetchIdRef = React.useRef(0);

  useEffect(() => {
    return () => {
      dispatch(crqClearResult());
      dispatch(crqSetFilter({}));
    };
  }, [dispatch]);

  const rows = useSelector((state) => state.caseRequestList.data);
  const total = useSelector((state) => state.caseRequestList.meta.total);
  const loading = useSelector((state) => state.caseRequestList.meta.loading);
  const forceReload = useSelector(
    (state) => state.caseRequestList.meta.forceReload
  );
  // const [isloading, setIsLoading] = useState(false);
  // const filter = useSelector((state) => state.caseRequestList.meta.filter);

  const [filter, SetFilter] = useState({});

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 10,
  });

  useEffect(() => {
    dispatch(crqMarkLoading(true));
    // SetFetchError(false);
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    // Only update the data if this is the latest fetch
    dispatch(
      crqFetch(
        rowsState.pageSize,
        rowsState.page,
        undefined,
        fetchId,
        fetchIdRef,
        caseId
      )
    ).catch(({ response }) => {
      console.log("action error");
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.log(errors);
      }
      SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      dispatch(crqClearResult());
      dispatch(crqMarkLoading(false));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, rowsState.page, rowsState.pageSize, forceReload]);

  // add request modal
  const [openModal, setOpenModal] = React.useState(false);
  // const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  // delete record confirmation
  const [cfDel, setCfDel] = useState(false);
  const handleCfDelClose = () => setCfDel(false);
  const handleCfDelOk = () => {
    return api
      .delete(`/api/cases/${caseId}/pol_requests/${cfDel}`)
      .then(() => {
        dispatch(forceReloadRequests());
      })
      .catch(() => {
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      });
  };

  // const [pdfDownloading, setPdfDownloading] = useState(false);
  // const [loadingPDF, setLoadingPDF] = useState(false);

  const [fetchInfo, SetFetchInfo] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };

  // const handleDownload = (uri, setActionState) => {
  //   setLoadingPDF(true);
  //   setActionState(true);
  //   api
  //     .post(
  //       uri,
  //       {
  //         // new_status: "sent_to_bank",
  //       },
  //       {
  //         responseType: "arraybuffer",
  //       }
  //     )
  //     .then((response) => {
  //       // handleCancel({ type: "success", message: "สำเร็จ" });
  //       const contentDisposition = response.headers["content-disposition"];
  //       const filename = /filename=.+/.exec(contentDisposition)
  //         ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
  //         : "download";
  //       fileDownload(response.data, filename);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       SetFetchInfo({ type: "error", payload: "เกิดข้อผิดพลาด" });
  //     })
  //     .finally(() => {
  //       setLoadingPDF(false);
  //       setActionState(false);
  //     });
  // };

  useEffect(() => {
    if (filter) {
      const fetchId = ++fetchIdRef.current;
      dispatch(
        crqFetch(
          rowsState.pageSize,
          rowsState.page,
          filter,
          fetchId,
          fetchIdRef,
          caseId
        )
      ).catch(({ response }) => {
        console.log("action error");
        if (response && response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);
          console.log(errors);
        }
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
        dispatch(crqClearResult());
        dispatch(crqMarkLoading(false));
      });
    }
  }, [caseId, dispatch, filter, rowsState.page, rowsState.pageSize]);

  const downloadFile = useCallback(async (fileUrl) => {
    try {
      setAnchorElDowload(null);
      // setIsLoading(true);
      const res = await api.get(fileUrl, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([res.data]));
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      let headerLine = res.headers["content-disposition"];
      let rawFilename = headerLine.split("filename=")[1];
      let filename = rawFilename.replace(/[^A-Za-z0-9.]/g, "");
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setIsError(true);
    }
    // finally {
    //   setIsLoading(false);
    // }
  }, []);

  return (
    <Paper
      sx={{
        p: 3,
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
      id="letter"
    >
      <ConfirmDialog
        open={cfDel ? true : false}
        onClose={handleCfDelClose}
        cfBtnProps={{ label: "ลบ", color: "error", startIcon: <Delete /> }}
        onConfirm={handleCfDelOk}
        title="ยืนยันการลบข้อมูล"
      >
        ยืนยันการลบ รายการคำขอ (ID: {cfDel})
      </ConfirmDialog>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Snackbar
        open={fetchInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity={fetchInfo.type || "warning"}
          sx={{ width: "100%" }}
        >
          {fetchInfo.payload}
        </Alert>
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>{openModal}</Box>
      </Modal>
      <Typography gutterBottom variant="h6">
        รายการคำขอ
      </Typography>
      <RequestListSearchForm
        onSubmit={(val) => {
          SetFilter(val);
        }}
        onReset={() => {
          SetFilter({});

        }}

      />
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={2}
        sx={{ mb: 2 }}
      >
        <LoadingButton
          type="button"
          variant="contained"
          color="info"
          startIcon={<Download />}
          loadingPosition="start"
          // loading={pdfDownloading || isloading}
          // disabled={loadingPDF}
          onClick={handleClickDowload}
          aria-controls={openDowload ? "dowload-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openDowload ? "true" : undefined}
        >
          ดาวน์โหลด
        </LoadingButton>
        {cases.includes("download") && (
          <Menu
            id="dowload-menu"
            anchorEl={anchorElDowload}
            open={openDowload}
            onClose={handleCloseDownload}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={async () => {
                await downloadFile(
                  `/api/cases/${caseId}/pol_requests?page_size=10&page=0&export=excel`,
                  "File"
                );
              }}
            >
              ดาวน์โหลด xlsx
            </MenuItem>
            <MenuItem
              onClick={async () => {
                await downloadFile(
                  `/api/cases/${caseId}/pol_requests?page_size=10&page=0&export=csv`,
                  "File"
                );
              }}
            >
              ดาวน์โหลด csv
            </MenuItem>
            <MenuItem
              onClick={async () => {
                await downloadFile(
                  `/api/cases/${caseId}/pol_requests?page_size=10&page=0&export=pdf`,
                  "File"
                );
              }}
            >
              ดาวน์โหลด pdf
            </MenuItem>
            <MenuItem
              onClick={async () => {
                await downloadFile(
                  `/api/cases/${caseId}/pol_requests?page_size=10&page=0&export=zip`,
                  "File"
                );
              }}
            >
              ดาวน์โหลดเอกสาร zip
            </MenuItem>
          </Menu>
        )}
        {cases.includes("create") && (
          <>
            <LoadingButton
              id="export-button-excel"
              type="button"
              variant="contained"
              startIcon={<AccountBalance />}
              loadingPosition="start"
              // onClick={() => handleDownload("/api/", setPdfDownloading)}
              aria-controls={
                openAddBankRequest ? "add-bank-request-menu" : undefined
              }
              aria-haspopup="true"
              aria-expanded={openAddBankRequest ? "true" : undefined}
              onClick={handleOpenAddBankRequest}
            >
              เพิ่มคำขอธนาคาร
            </LoadingButton>
            <Menu
              id="add-bank-request-menu"
              anchorEl={anchorElAddBankRequest}
              open={openAddBankRequest}
              onClose={handleCloseAddBankRequest}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => navigate(`/cases/${caseId}/requests/add`)}
              >
                เพิ่มคำขอธนาคาร
              </MenuItem>
              <MenuItem
                onClick={() => navigate(`/cases/${caseId}/requests/addH`)}
              >
                เพิ่มคำขอธนาคารหมาย H
              </MenuItem>
            </Menu>
            <Button
              component={Link}
              to={`/cases/${caseId}/requests-telco/add`}
              variant="contained"
              color="success"
              startIcon={<SendToMobile />}
            >
              เพิ่มคำขอโทรศัพท์
            </Button>
          </>
        )}
      </Stack>
      <DataGrid
        disableSelectionOnClick
        disableColumnMenu
        columns={columns}
        rows={rows}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 20]}
        pagination
        {...rowsState}
        paginationMode="server"
        onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
        onPageSizeChange={(pageSize) =>
          setRowsState((prev) => ({ ...prev, page: 0, pageSize }))
        }
        autoHeight
        getRowHeight={() => 80}
      />
      <Dialog
        open={isError}
        onClose={() => setIsError(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ไม่สามารถดาวน์โหลดไฟล์ได้ เนื่องจากไฟล์ไม่มีอยู่ในระบบ
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsError(false);
            }}
          >
            ปิด
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
